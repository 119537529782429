import React from "react";
import PropTypes from "prop-types";
import { DONE } from "../../redux/globalStatus";
import { CheckCircleIcon } from "@heroicons/react/outline";

const ProgressingStatus = (props) => {
  const { message, handleOnClick, status } = props;
  const loadingIcon = () => {
    return (
      <svg
        className="animate-spin -ml-1 mr-3 h-10 w-10 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25 text-blue-1000"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    );
  };

  return (
    <div className="flex flex-col justify-items-center items-center place-content-center h-full p-10 border-2 border-dashed border-white bg-white rounded-3xl">
      <div className="bg-transparent text-blue-1000 flex flex-col items-center justify-items-center text-center space-y-5 mb-10">
        {status === DONE ? (
          <CheckCircleIcon className="text-blue-1000 font-semibold h-14 w-14" />
        ) : (
          loadingIcon()
        )}
        <p className="font-medium text-lg mb-10 text-gray-900">{message}</p>
      </div>
      <button
        onClick={handleOnClick}
        className="py-3 px-6 text-lg font-bold bg-blue-1000 rounded-2xl text-white border-dotted bottom-2 border-blue-1000"
      >
        {status === DONE ? "DONE" : ""}
      </button>
    </div>
  );
};

ProgressingStatus.propTypes = {
  message: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default ProgressingStatus;
