import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BusinessInsightCard from "./BusinessInsightCard";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import { ComputeCommission, totalRechargeSold } from "../transactions/models";
import { useSelector } from "react-redux";
import { getRole } from "../authentication/models";
import { selectSettingsByType } from "../settings/settingsSlice";
import { settingsType } from "../settings/settingsStatus";

const BusinessInsightCardCollections = ({ business, title }) => {
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) =>
    selectSettingsByType(state, settingsType.BUSINESS)
  );

  const [totalNumberTransaction, setTotalNumberTransaction] = useState(0);
  const [totalRecharge, setTotalSold] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  useEffect(() => {
    async function loadBusinessInsight() {
      if (business === undefined || business.uid === undefined) return;

      const todayTrans = await FirestoreTransactions.LoadByBusinessId(
        business.uid
      );
      if (todayTrans === null || todayTrans.length === 0) {
        setTotalNumberTransaction(0);
        return;
      }

      setTotalNumberTransaction(todayTrans.length);

      const totlaSold = totalRechargeSold(todayTrans);
      setTotalSold(totlaSold);

      const userRole = getRole(user?.data?.roles);
      const commission = ComputeCommission(totlaSold, userRole, settings);
      setTotalCommission(commission);
    }

    loadBusinessInsight();
  }, [business.uid]);

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5">
          <BusinessInsightCard
            title="Total Num of Recharge"
            data={totalNumberTransaction}
            simbol="Recharge"
          />
          <BusinessInsightCard
            title="Total Recharg Sold"
            data={totalRecharge}
            simbol="USD"
          />
          <BusinessInsightCard
            title="My Commission"
            data={totalCommission}
            simbol="USD"
          />
        </dl>
      </div>
    </>
  );
};

BusinessInsightCardCollections.propTypes = {
  title: PropTypes.string.isRequired,
  business: PropTypes.object.isRequired,
};

export default BusinessInsightCardCollections;
