import { createSlice } from "@reduxjs/toolkit";
import { DATE_FILTER } from "./filterStatus";

const initialState = { status: DATE_FILTER, date: new Date().toDateString() };

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    filterTypeAdd(state, action) {
      return { ...state, filterType: action.payload };
    },
    filterPhoneAdd(state, action) {
      return { ...state, phone: action.payload };
    },
    filterDateAdd(state, action) {
      return { ...state, date: action.payload };
    },
    filterDateStartAdd(state, action) {
      return { ...state, dateStart: action.payload };
    },
    filterDateEndAdd(state, action) {
      return { ...state, dateEnd: action.payload };
    },
    filterReferenceAdd(state, action) {
      return { ...state, dateEnd: action.payload };
    },
    filterOperatorIdAdd(state, action) {
      return { ...state, operatorId: action.payload };
    },
  },
});

export const {
  filterDateAdd,
  filterDateEndAdd,
  filterDateStartAdd,
  filterOperatorIdAdd,
  filterPhoneAdd,
  filterReferenceAdd,
} = filtersSlice.actions;

export default filtersSlice.reducer;
