/**
 * Compute Top-Up Services Feeds
 *
 * Args:
 *      amount (Number): The amount to be converted into another currency.
 *      stripeProcessingFees (string): Stripe Processing Fee and it is usually 2.9%
 * 		StripFixeFees (string) : Stripe Processing Fixe Fee and it usually 0.30 cents
 * 		additionalFee (string): JLRecharge Fee and it's variable
 *
 * Returns:
 *      (String): Amount Converted
 *
 */

import { ROLES } from "../features/authentication/models";

export const topUpFees = (
  amount,
  stripeProcessingFees,
  stripeFixeFees,
  additionalFee
) => {
  if (amount === 0 || amount === undefined) return 0;
  if (stripeProcessingFees === 0 || stripeProcessingFees === undefined)
    return 0;
  if (stripeFixeFees === 0 || stripeFixeFees === undefined) return 0;
  if (additionalFee === 0 || additionalFee === undefined) return 0;

  const totalFees =
    stripeProcessingFees * Number(amount) +
    Number(stripeFixeFees) +
    Number(additionalFee);
  return totalFees;
};

/**
 * Compute Fee to User Country
 *
 * Args:
 *      fee (Number): The fee value to be converted into another currency.
 *      rate: the sender country rate
 *
 * Returns:
 *      (String): fee Converted
 *
 */
export const convertBasedUSAFee = (fee, rate) => {
  if (rate === null || rate === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  if (fee === null || fee === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  // Example: USD - CVE
  // amount = (amount CVE * 1 USD) * rates CVE

  let amountConverted = Number(fee) * Number(rate);

  // Two Digit
  return amountConverted.toFixed(2);
};

/**
 * Compute Top-Up Amount Sent
 *
 * Args:
 *      amount (Number): The Amount in Sender Currency.
 *      commision (Number): JLRechagre Commission based on Providers *
 * Returns:
 *      (Number): Amount in Sender Currency plus JLrecharge commission in Credit
 *
 */

export const giveCommissionAway = (amount, commision) => {
  if (amount === 0 || amount === undefined) return 0;
  if (commision === 0 || commision === undefined) return amount;
  const totalAmountSent = commision * Number(amount) + Number(amount);
  return totalAmountSent;
};

/**
 * Compute Commission for Manager / Agent
 *
 * Args:
 *      roles: User Roles
 * Returns:
 *      (Number): Return the commission based on the role
 *
 */

export const getCommission = (roles, settings) => {
  if (roles === undefined) {
    throw new Error(
      "User Missing Role - Contact support: support@jlrecharge.com"
    );
  }

  if (settings === undefined) {
    throw new Error(
      "Business Setting Missing - Contact support: support@jlrecharge.com"
    );
  }

  // IF User has Manager -  return settings Manager Commission
  if (roles[ROLES.manager]) return settings?.managerCommission;

  // IF User has Agent -  return settings Agent Commission / Same as maneger Commission.
  // The truth is that agent does not have commsion
  if (roles[ROLES.agent]) return settings?.managerCommission;

  // Partner / Employe - same commission value. The truth is that employee does not have commsion
  if (roles[ROLES.partner]) return settings?.partnerCommission;
  if (roles[ROLES.employee]) return settings?.partnerCommission;

  // Return user Commission
  return settings?.userCommission;
};
