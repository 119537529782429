import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreCountries {
  static async LoadActive() {
    try {
      const countryCollection = [];
      const queryCountries = query(
        collection(db, "countries"),
        where("isActive", "==", true)
      );

      const countriesSnapShot = await getDocs(queryCountries);

      countriesSnapShot.forEach((snapshot) => {
        countryCollection.push(snapshot.data());
      });
      return countryCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadAll() {
    try {
      const countryCollection = [];
      const queryCountries = query(collection(db, "countries"));

      const countriesSnapShot = await getDocs(queryCountries);

      countriesSnapShot.forEach((snapshot) => {
        countryCollection.push(snapshot.data());
      });
      return countryCollection;
    } catch (error) {
      return null;
    }
  }
}
