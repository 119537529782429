export const isEmpty = (value) => {
  return (
    value === null || value === "" || value?.length <= 0 || value === undefined
  );
};

export const getInitial = (user) => {
  try {
    if (!isEmpty(user.name)) {
      const name = user.name.split(" ");
      if (name !== undefined && name.length > 1) {
        return `${name[0].substring(0, 1)}.${name[1].substring(0, 1)}`;
      } else {
        return user.name.substring(0, 1);
      }
    }

    if (!isEmpty(user.displayName)) {
      const name = user.displayName.split(" ");
      if (name !== undefined && name.length > 1) {
        return `${name[0].substring(0, 1)}.${name[1].substring(0, 1)}`;
      } else {
        return user.displayName.substring(0, 1);
      }
    }

    return "JLR";
  } catch (error) {
    return "JLR";
  }
};
