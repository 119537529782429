import React, { useEffect } from "react";
import { compose } from "redux";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import withSidebar from "../../components/HOC/withSidebar";
import WalletAddFund from "../../features/wallets/WalletAddFund";
import WalletFund from "../../features/wallets/WalletFund";
import WalletProcessing from "../../features/wallets/WalletProcessing";
import { useSelector, useDispatch } from "react-redux";
import { WALLETS_READY } from "../../features/wallets/walletStatus";
import FirestorePaymentMethod from "../../api/firebase/firestore.paymentMethods";
import {
  paymentMethodsAddMany,
  paymentMethodStatus,
} from "../../features/paymentMethods/paymentMethodsSlice";
import { PAYMENTMETHODS_READY } from "../../features/paymentMethods/paymentMethodState";
import AuthRecharge from "../../features/wallets/AuthRecharge";
import withWallet from "../../components/HOC/withWallet";

const AddMoney = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    async function loadPms() {
      if (wallet?.data === undefined) return;

      const paymentMethods = await FirestorePaymentMethod.LoadByWalletId(
        wallet?.data?.uid
      );
      if (paymentMethods !== null && paymentMethods.length > 0) {
        dispatch(paymentMethodsAddMany(paymentMethods));
        dispatch(paymentMethodStatus(PAYMENTMETHODS_READY));
      }
    }
    loadPms();
  }, [wallet?.data]);

  if (wallet.status !== WALLETS_READY) {
    return <WalletProcessing />;
  }
  return (
    <div className="font-Poppins">
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Manage my Wallet
          </h2>
        </div>
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <WalletFund
                amount={wallet?.data?.amount}
                currency={user?.data?.currencyCode}
              />
              <WalletAddFund />
            </div>
          </div>
        </main>
        <aside className="hidden relative xl:flex xl:flex-col flex-shrink-0 w-1/3 border-l border-gray-200 overflow-y-auto">
          <div className="inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="rounded-lg">
              <AuthRecharge />
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar, withWallet)(AddMoney);
