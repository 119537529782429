import {
  getFirestore,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreLogs {
  static async Add(logData) {
    try {
      const newLogData = logData;
      const pmRef = doc(collection(db, "logs"));

      newLogData.uid = pmRef.id;
      newLogData.date = serverTimestamp();

      await setDoc(pmRef, { ...newLogData }, { merge: true });

      return newLogData;
    } catch (error) {
      return error;
    }
  }
}
