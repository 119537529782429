import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { applyRateToReceiver } from "../../utils/currency";
import { getCommission, topUpFees } from "../../utils/fees";
import { isEmpty } from "../../utils/stringHelper";
import { selectRatesBase } from "../rates/ratesSlice";
import { selectSettingsByType } from "../settings/settingsSlice";
import { settingsType } from "../settings/settingsStatus";
import {
  rechargeAmountReceivedAdd,
  rechargeAmountSentAdd,
  rechargeFeesAdd,
  rechargeRatesAdd,
  rechargeTotalAmountAdd,
  rechargeUserCommission,
} from "./rechargeSlice";

const RechargeAmount = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const recharge = useSelector((state) => state.recharge);
  const baseRates = useSelector((state) =>
    selectRatesBase(state, user?.data?.currencyCode)
  );
  const setting = useSelector((state) =>
    selectSettingsByType(state, settingsType.BUSINESS)
  );

  const [message, setErrorMessage] = useState("");

  const [amount, setAmount] = useState("");

  const handleRechargeAmount = (data) => {
    setAmount(data.target.value);
    if (Number(data.target.value) <= 0) {
      setErrorMessage(`Amount minimun must be more than ${data.target.value}`);
      return;
    }

    if (Number(data.target.value) > 50) {
      setErrorMessage("Amount maximun allowed is $50");
      dispatch(rechargeAmountReceivedAdd(0));
      dispatch(rechargeTotalAmountAdd(""));
      dispatch(rechargeFeesAdd(""));
      dispatch(rechargeRatesAdd(0));
      dispatch(rechargeUserCommission(""));
      return;
    }

    setErrorMessage("");
    dispatch(rechargeAmountSentAdd(data.target.value));

    // Handle Partner / Manager Commission:
    const userCommissionBasedRole = getCommission(user?.data?.roles, setting);
    // TODO: Dynamicly get the commission from operators
    let userCommission =
      Number(data.target.value) * 0.1 * userCommissionBasedRole;

    // Handle Amount User Will Received
    let amountWillReceived = parseFloat(data.target.value) - userCommission;

    // Compute JLRechage Fee
    const fees = topUpFees(
      Number(data.target.value),
      Number(setting?.processingFee),
      Number(setting?.processingFixeFee),
      Number(setting?.additionalFee)
    );

    // Convert amount sent on destination rates
    const rateValue = baseRates.rates[recharge.receiverCurrencyCode];

    // Amount Received
    amountWillReceived = amountWillReceived - fees;
    let amountReceived = applyRateToReceiver(amountWillReceived, rateValue);
    amountReceived = parseInt(amountReceived);

    let totalAmount = Number(data.target.value) + Number(fees);

    // Apply Discout for user
    totalAmount = Number(data.target.value);
    dispatch(rechargeAmountReceivedAdd(amountReceived));
    dispatch(rechargeTotalAmountAdd(totalAmount.toFixed(2)));
    dispatch(rechargeFeesAdd(fees.toFixed(2)));
    dispatch(rechargeRatesAdd(rateValue));
    dispatch(rechargeUserCommission(userCommission.toFixed(2)));
  };

  if (isEmpty(recharge.phone) || isEmpty(recharge.operatorName)) {
    return <></>;
  }

  return (
    <div className="mt-10">
      <label
        htmlFor="price"
        className="block text-gray-700 text-lg font-semibold tracking-wide"
      >
        Amount
      </label>
      <div className="mt-1 relative rounded-md shadow-md">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-base font-bold">$</span>
        </div>
        <input
          value={amount}
          type="text"
          name="amount"
          id="amount"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 border-gray-50 rounded-md py-4 sm:text-lg font-bold text-gray-600"
          placeholder="0.00"
          aria-describedby="price-currency"
          onChange={handleRechargeAmount}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span
            className="text-gray-500 font-bold sm:text-base"
            id="price-currency"
          >
            {user?.data?.countryCode}
          </span>
        </div>
      </div>
      <p className="text-xs font-bold text-red-600">{message}</p>
    </div>
  );
};

export default RechargeAmount;
