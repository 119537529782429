import React, { useEffect } from "react";
import { compose } from "redux";
import { useSelector, useDispatch } from "react-redux";
import FirestorePaymentMethod from "../../api/firebase/firestore.paymentMethods";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import withSidebar from "../../components/HOC/withSidebar";
import OperatorCollection from "../../features/operators/OperatorCollection";
import {
  loadOperatorsByCountry,
  operatorsAddMany,
} from "../../features/operators/operatorsSlice";
import {
  paymentMethodsAddMany,
  paymentMethodStatus,
} from "../../features/paymentMethods/paymentMethodsSlice";
import { PAYMENTMETHODS_READY } from "../../features/paymentMethods/paymentMethodState";

import PhoneNumber from "../../features/recharge/PhoneNumber";
import RechargeDetails from "../../features/recharge/rechargeDetails";
import RechargeProcessing from "../../features/recharge/RechargeProcessing";
import {
  rechargeAdd,
  rechargeCountryCallingCode,
  rechargePaymentMethodIdAdd,
  rechargeReceiverCountryCodeAdd,
  rechargeReceiverCountryName,
  rechargeReceiverCurrencyCodeAdd,
} from "../../features/recharge/rechargeSlice";
import {
  RECHARGE_COMPLETE,
  RECHARGE_UNKNOWN,
} from "../../features/recharge/rechargeStatus";
import { TRANSACTION_DRAFT } from "../../features/transactions/transactionState";
import WalletState from "../../features/wallets/WalletState";
import PaymentMethods from "../../features/wallets/PaymentMethods";
import { WALLETS_READY } from "../../features/wallets/walletStatus";
import { isEmpty } from "../../utils/stringHelper";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import { selectSettingsByType } from "../../features/settings/settingsSlice";
import { selectedCountryByIsoName } from "../../features/countries/countriesSlice";
import FirestoreOperators from "../../api/firebase/firestore.operators";
import RechargeAmount from "../../features/recharge/RechargeAmount";
import AutoRechargeConfirmation from "../../features/wallets/AutoRechargeConfirmation";

const Recharge = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const recharge = useSelector((state) => state.recharge);
  const wallet = useSelector((state) => state.wallet);

  const businessSettings = useSelector((state) =>
    selectSettingsByType(state, "business")
  );
  const country = useSelector((state) =>
    selectedCountryByIsoName(state, businessSettings?.defaultCountryIsoName)
  );

  useEffect(() => {
    async function rechargeBootstrap() {
      //Load All Saved Payment Method in Wallets
      if (isEmpty(user.data?.uid)) return;

      const transactionDraft = await FirestoreTransactions.LoadByUserandStatus(
        user?.data?.uid,
        TRANSACTION_DRAFT
      );
      if (transactionDraft !== undefined && transactionDraft.length > 0) {
        dispatch(rechargeAdd(transactionDraft[0]));
        dispatch(
          loadOperatorsByCountry(transactionDraft[0].receiverCountryCode)
        );
      }

      const paymentMethods = await FirestorePaymentMethod.LoadByWalletId(
        wallet?.data?.uid
      );
      if (paymentMethods !== null && paymentMethods.length > 0) {
        dispatch(rechargePaymentMethodIdAdd(wallet?.data?.paymentMethodId));
        dispatch(paymentMethodsAddMany(paymentMethods));
        dispatch(paymentMethodStatus(PAYMENTMETHODS_READY));
      }

      if (country === undefined) return;

      if (isEmpty(recharge.receiverCountryCode) && !isEmpty(country?.isoName)) {
        dispatch(rechargeReceiverCountryCodeAdd(country?.isoName));
      }

      if (
        isEmpty(recharge.receiverCurrencyCode) &&
        !isEmpty(country?.currencyCode)
      ) {
        dispatch(rechargeReceiverCurrencyCodeAdd(country?.currencyCode));
      }

      if (
        isEmpty(recharge.countryCallingCode) &&
        !isEmpty(country?.callingCodes[0])
      ) {
        dispatch(rechargeCountryCallingCode(country?.callingCodes[0]));
      }

      if (isEmpty(recharge.receiverCountryName) && !isEmpty(country?.name)) {
        dispatch(rechargeReceiverCountryName(country?.name));
      }

      if (!isEmpty(country?.isoName)) {
        const operators = await FirestoreOperators.LoadByCountry(
          country?.isoName
        );
        if (operators !== undefined && operators.length > 0) {
          dispatch(operatorsAddMany(operators));
        }
      }
    }
    rechargeBootstrap();
  }, [wallet?.data?.uid]);

  const renderRechargeComponent = () => {
    //No Payment Method
    if (
      wallet.status === WALLETS_READY &&
      isEmpty(wallet?.data?.paymentMethodId)
    ) {
      return (
        <WalletState
          title="Add Payment Method"
          description="First time here! Setup Payment Method to sent credit to your loves ones."
        />
      );
    }
    // Recharge - Initial state / complete Recharge
    if (
      recharge.status === RECHARGE_UNKNOWN ||
      recharge.status === RECHARGE_COMPLETE
    ) {
      return (
        <>
          <PhoneNumber />
          <OperatorCollection />
          <RechargeAmount />
        </>
      );
    }
    return <RechargeProcessing />;
  };

  return (
    <div className="font-Poppins">
      <div className="md:flex md:items-center md:justify-between mb-4">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Recharge
          </h2>
        </div>
      </div>
      <div className="mb-6">
        <AutoRechargeConfirmation />
      </div>
      <div className="flex-1 relative z-0 flex flex-col lg:flex-row bg-white rounded-3xl overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">{renderRechargeComponent()}</div>
          </div>
        </main>
        <aside className="relative xl:flex md:flex-col flex-shrink-0 w-full lg:w-2/5 border-l border-gray-200 overflow-y-auto">
          <div className="inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="rounded-lg">
              <RechargeDetails user={user} recharge={recharge} />
              <PaymentMethods />
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(Recharge);
