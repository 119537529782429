import { getAuth, getIdToken } from "firebase/auth";
import { getAppCheckToken } from "../firebase/firebase.appcheck";
import { HttpCommunication } from "../http";

export class HttpClient {
  constructor(url) {
    this.Communication = new HttpCommunication(url);
  }
  async Post(node, data) {
    const auth = getAuth();
    const { currentUser } = auth;
    const userToken = await getIdToken(currentUser, true);

    const { token } = await getAppCheckToken(window.appCheck);

    return this.Communication.Post(node, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "X-Firebase-AppCheck": token,
      },
    });
  }
}
