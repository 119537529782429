export const WALLETS_UNKNOWN = "wallets_unknown";
export const WALLETS_LOADING = "wallets_loading";
export const WALLETS_LOAD_SUCESS = "wallets_load_success";
export const WALLETS_LOAD_FAILED = "wallets_load_failed";

export const WALLETS_ADD_PAYMENTMETHOD = "wallets_add_paymentmethod";
export const WALLETS_ADD_PAYMENTMETHOD_PENDING =
  "wallets_add_paymentmethod_pending";
export const WALLETS_ADD_PAYMENTMETHOD_FAILED =
  "wallets_add_paymentmethod_failed";
export const WALLETS_ADD_PAYMENTMETHOD_COMPLETE =
  "wallets_add_paymentmethod_complete";

export const WALLETS_UPDATE_CARD = "wallets_update_card";
export const WALLETS_UPDATE_CARD_PENDING = "wallets_update_card_pending";
export const WALLETS_UPDATE_CARD_FAILED = "wallets_update_card_failed";
export const WALLETS_UPDATE_CARD_COMPLETE = "wallets_update_card_complete";

export const WALLETS_FUND_ADD_PENDING = "wallets_fund_add_pending";
export const WALLETS_FUND_ADD_FAILED = "wallets_fund_add_failed";
export const WALLETS_FUND_ADD_SUCCESS = "wallets_fund_add_success";

export const WALLETS_READY = "wallets_ready";
export const WALLET_ADD_SUCCEEDED = "succeeded";

export const WALLET_RECHARGE_FAILED = "WALLET_RECHARGE_FAILED";
export const WALLET_NOT_FOUND = "WALLET_NOT_FOUND";
export const WALLET_AUTO_RECHARGE_DISABLED = "WALLET_AUTO_RECHARGE_DISABLED";
export const WALLET_AMOUNT_HIGHER_THAN_MINIMUM =
  "WALLET_AMOUNT_HIGHER_THAN_MINIMUM";
export const WALLET_ALREADY_PROCCESSED = "WALLET_ALREADY_PROCCESSED";
export const WALLET_UNKNOWN_ERROR = "WALLET_UNKNOWN_ERROR";
