/* ------------------------------------------------------------------------------------------------
Phone Number Input Demo: https://catamphetamine.gitlab.io/react-phone-number-input
---------------------------------------------------------------------------------------------------*/

import React, { useEffect, useState } from "react";
import Input, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import { useSelector, useDispatch } from "react-redux";
import CountrySelector from "./CountrySelector";
import {
  rechargeCountryCallingCode,
  rechargePhoneAdd,
  rechargeReceiverCountryCodeAdd,
  rechargeReceiverCountryName,
  rechargeReceiverCurrencyCodeAdd,
  rechargeReset,
} from "./rechargeSlice";
import { isEmpty } from "../../utils/stringHelper";
import { concactCallingCodeAndPhone } from "../../utils/phoneHelper";
import { operatorsSetAll } from "../operators/operatorsSlice";
import FirestoreOperators from "../../api/firebase/firestore.operators";

const PhoneNumber = () => {
  const recharge = useSelector((state) => state.recharge);
  const [phone, setPhone] = useState(recharge.phone);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const handlePhoneNumber = (data) => {
    if (isEmpty(data?.trim())) {
      return;
    }
    setPhone(data?.trim());
    const addPhoneCountryCode = concactCallingCodeAndPhone(
      recharge.countryCallingCode,
      data
    );
    if (isPossiblePhoneNumber(addPhoneCountryCode)) {
      dispatch(rechargePhoneAdd(addPhoneCountryCode));
      setMessage("");
    } else {
      setMessage("Invalid Phone Number.");
    }
  };

  const handleCountrySelect = async (data) => {
    if (recharge.receiverCountryCode === data.isoName) return;
    dispatch(rechargeReset(""));
    dispatch(rechargeReceiverCountryCodeAdd(data.isoName));
    dispatch(rechargeReceiverCurrencyCodeAdd(data?.currencyCode));
    dispatch(rechargeCountryCallingCode(data.callingCodes[0]));
    dispatch(rechargeReceiverCountryName(data?.name));

    const operators = await FirestoreOperators.LoadByCountry(data.isoName);
    if (operators !== undefined && operators.length > 0) {
      dispatch(operatorsSetAll(operators));
    }
  };

  useEffect(() => {
    if (recharge === undefined) return;
    if (recharge.phone === undefined) return;

    // Remove Country Code.
    setPhone(recharge.phone);
  }, [recharge.phone]);

  return (
    <div>
      <h2 className="text-gray-700 text-lg font-semibold tracking-wide mb-2">
        Enter Country & Phone Number
      </h2>
      <div className="flex flex-col md:flex-row">
        <CountrySelector onChange={handleCountrySelect} />
        <div className="w-full">
          <Input
            className="mt-1 w-full p-8 h-14 rounded-r-md border-l-0 border-y-2 border-r-2 border-gray-100
								 bg-white py-2 shadow-sm focus:border-indigo-500 
                                  focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-lg font-bold text-gray-600"
            placeholder="Enter phone number"
            onChange={handlePhoneNumber}
            value={phone}
            defaultCountry={recharge.receiverCountryCode}
          />
          <p className="text-sm text-red-600 font-medium">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
