import {
  initializeAppCheck,
  getToken,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import firebaseInit from "./firebase.index";

export const initAppCheck = (app) => {
  try {
    if (window.initializedAppCheck) {
      return;
    }
    if (process.env.NODE_ENV === "development") {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY
      ),
      isTokenAutoRefreshEnabled: true,
    });
    window.initializedAppCheck = true;

    return appCheck;
  } catch (error) {
    return null;
  }
};

export const getAppCheckToken = async () => {
  try {
    const app = firebaseInit();

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY
      ),
      isTokenAutoRefreshEnabled: true,
    });
    const tokenResulted = await getToken(appCheck, true);
    return tokenResulted;
  } catch (error) {
    return null;
  }
};
