import React from "react";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import withSidebar from "../../components/HOC/withSidebar";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import AgentForm from "../../features/business/AgentForm";
import { useSelector } from "react-redux";
import { selectBusinessById } from "../../features/business/businessSlice";

const BusinessAddAgent = () => {
  const params = useParams();
  const business = useSelector((state) =>
    selectBusinessById(state, params.businessId)
  );

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Add Authorized Agent
          </h2>
        </div>
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          {/* Start main area*/}
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <AgentForm business={business} />
            </div>
          </div>
          {/* End main area */}
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(BusinessAddAgent);
