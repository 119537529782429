import { PaymentIntent } from "../api/stripe/model";
import { StripeClient } from "../api/stripe/client";
import { SUCCESS } from "../api/http";
import { get } from "lodash";
import {
  WALLETS_READY,
  WALLET_ALREADY_PROCCESSED,
  WALLET_AMOUNT_HIGHER_THAN_MINIMUM,
  WALLET_AUTO_RECHARGE_DISABLED,
  WALLET_NOT_FOUND,
  WALLET_RECHARGE_FAILED,
  WALLET_UNKNOWN_ERROR,
} from "../features/wallets/walletStatus";
import { isEmpty } from "./stringHelper";
import { DASHBOARD } from "../router/routeNames";

export const executeWalletAutoRecharge = async (wallet, user, stripe) => {
  try {
    // Check Wallet
    if (wallet === undefined || wallet?.data === undefined) {
      return WALLET_NOT_FOUND;
    }

    const autoRecharge = get(wallet, "data.autoRecharge");
    // Auto Recharge False - No process
    if (!autoRecharge) {
      return WALLET_AUTO_RECHARGE_DISABLED;
    }

    const walletAmount = get(wallet, "data.amount");
    const walletMinBalance = get(wallet, "data.minBalance");
    // If Wallet Balance is Greater that min Balance - no process
    if (walletAmount >= walletMinBalance) {
      return WALLET_AMOUNT_HIGHER_THAN_MINIMUM;
    }

    // Wallets is already being processed
    if (
      wallet.status !== WALLETS_READY &&
      wallet.status !== WALLET_UNKNOWN_ERROR
    ) {
      return WALLET_ALREADY_PROCCESSED;
    }

    let paymentIntentData = new PaymentIntent(
      user.data,
      wallet.data,
      wallet.data.balanceAmount
    );
    paymentIntentData.statement_descriptor = "Auto Recharge";
    paymentIntentData.description = `Owner - ${
      user.data.name || user.data.email
    }`;
    const stripeClient = new StripeClient(
      process.env.REACT_APP_PAYMENT_INTENTS_URL
    );
    const paymentIntent = await stripeClient.Post(
      "/create-payment-intent",
      JSON.stringify(paymentIntentData)
    );
    const statusCode = get(paymentIntent, "data.statusCode");
    const status = get(paymentIntent, "status");
    if ((statusCode && statusCode !== 200) || status !== SUCCESS) {
      return WALLET_RECHARGE_FAILED;
    }

    const paymentClientSecret = get(paymentIntent, "data.clientSecret");
    if (isEmpty(paymentClientSecret)) {
      return WALLET_RECHARGE_FAILED;
    }
    const walletPaymentMethod = get(wallet, "data.paymentMethodId");
    const returnUrl = `${window.location.origin}${DASHBOARD}`;
    const result = await stripe.confirmCardPayment(paymentClientSecret, {
      payment_method: walletPaymentMethod,
      return_url: returnUrl,
    });
    const error = get(result, "error");
    if (error) {
      return WALLET_RECHARGE_FAILED;
    }

    const confirmationStatus = get(result, "paymentIntent.status");
    if (confirmationStatus !== "succeeded") {
      return WALLET_RECHARGE_FAILED;
    }

    const amountRecharge = get(result, "paymentIntent.amount") / 100;
    const walletData = {
      ...wallet.data,
      amount: wallet.data.amount + amountRecharge,
    };

    return {
      status: SUCCESS,
      wallet: walletData,
    };
  } catch (error) {
    return WALLET_RECHARGE_FAILED;
  }
};
