import { createSlice } from "@reduxjs/toolkit";
import { RECHARGE_UNKNOWN } from "./rechargeStatus";

const initialState = {
  status: RECHARGE_UNKNOWN,
  paymentMethodId: "",
  receiverCountryCode: "",
  receiverCountryName: "",
  receiverCurrencyCode: "",
  phone: "",
  amountReceived: "",
  amountSent: "",
  fees: "",
  totalAmount: "",
  rates: "",
  userCommission: "",
  operatorId: "",
};

const rechargeSlice = createSlice({
  name: "recharge",
  initialState,
  reducers: {
    rechargeStatus(state, action) {
      state.status = action.payload;
    },
    rechargeReset(state, action) {
      return {
        ...state,
        paymentMethodId: action.payload,
        receiverCountryCode: action.payload,
        receiverCountryName: action.payload,
        receiverCurrencyCode: action.payload,
        phone: action.payload,
        amountReceived: action.payload,
        amountSent: action.payload,
        fees: action.payload,
        totalAmount: action.payload,
        rates: action.payload,
        userCommission: action.payload,
        operatorId: "",
      };
    },
    rechargeAdd(state, action) {
      return action.payload;
    },
    rechargeTransactionId(state, action) {
      return { ...state, transactionId: action.payload };
    },
    rechargePhoneAdd(state, action) {
      return { ...state, phone: action.payload };
    },
    rechargeCountryCallingCode(state, action) {
      return { ...state, countryCallingCode: action.payload };
    },
    rechargeOperatorIdAdd(state, action) {
      return { ...state, operatorId: action.payload };
    },
    rechargeOperatorNameAdd(state, action) {
      return { ...state, operatorName: action.payload };
    },
    rechargeTotalAmountAdd(state, action) {
      return { ...state, totalAmount: action.payload };
    },
    rechargeRatesAdd(state, action) {
      return { ...state, rates: action.payload };
    },
    rechargePaymentMethodIdAdd(state, action) {
      return { ...state, paymentMethodId: action.payload };
    },
    rechargeWalletAdd(state, action) {
      return { ...state, walletId: action.payload };
    },
    rechargeReceiverCountryCodeAdd(state, action) {
      return { ...state, receiverCountryCode: action.payload };
    },
    rechargeReceiverCurrencyCodeAdd(state, action) {
      return { ...state, receiverCurrencyCode: action.payload };
    },
    rechargeReceiverCountryName(state, action) {
      return { ...state, receiverCountryName: action.payload };
    },
    rechargeSenderCountryCodeAdd(state, action) {
      return { ...state, senderCountryCode: action.payload };
    },
    rechargeSenderCurrencyCodeAdd(state, action) {
      return { ...state, senderCurrencyCode: action.payload };
    },
    rechargeAmountReceivedAdd(state, action) {
      return { ...state, amountReceived: action.payload };
    },
    rechargeAmountSentAdd(state, action) {
      return { ...state, amountSent: action.payload };
    },
    rechargeFeesAdd(state, action) {
      return { ...state, fees: action.payload };
    },

    rechargeUserCommission(state, action) {
      return { ...state, userCommission: action.payload };
    },
    rechargeMessageAdd(state, action) {
      return { ...state, message: action.payload };
    },
  },
});

export default rechargeSlice.reducer;

export const {
  rechargeReceiverCountryCodeAdd,
  rechargeReceiverCurrencyCodeAdd,
  rechargeReceiverCountryName,
  rechargeSenderCountryCodeAdd,
  rechargeSenderCurrencyCodeAdd,
  rechargeOperatorIdAdd,
  rechargeOperatorNameAdd,
  rechargePaymentMethodIdAdd,
  rechargePhoneAdd,
  rechargeCountryCallingCode,
  rechargeRatesAdd,
  rechargeStatus,
  rechargeTotalAmountAdd,
  rechargeAmountReceivedAdd,
  rechargeAmountSentAdd,
  rechargeWalletAdd,
  rechargeFeesAdd,
  rechargeMessageAdd,
  rechargeTransactionId,
  rechargeAdd,
  rechargeUserCommission,
  rechargeReset,
} = rechargeSlice.actions;
