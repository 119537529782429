import React from "react";
import { compose } from "redux";
import withSidebar from "../../components/HOC/withSidebar";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import BusinessForm from "../../features/business/BusinessForm";
import { useSelector } from "react-redux";
import {
  BUSINESS_ADD_SUCCESS,
  BUSINESS_READY,
} from "../../features/business/businessStatus";
import { BUSINESS } from "../../router/routeNames";
import BusinessState from "../../features/business/BusinessState";
const BusinessAdd = () => {
  const business = useSelector((state) => state.businesses);

  if (
    business.status !== BUSINESS_READY ||
    business.status === BUSINESS_ADD_SUCCESS
  ) {
    return <BusinessState route={BUSINESS} />;
  }

  return (
    <div className="font-Poppins">
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Add Your Business
          </h2>
        </div>
      </div>
      <div className="flex-1 flex-col lg:flex-row relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <BusinessForm />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(BusinessAdd);
