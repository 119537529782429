import React from "react";
import AuthEmailVerification from "../../features/authentication/AuthEmailVerification";

const EmailVerification = () => (
  <div className="flex items-center justify-center h-screen bg-gray-50">
    <div className="w-2/3">
      <AuthEmailVerification />
    </div>
  </div>
);

export default EmailVerification;
