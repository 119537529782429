import { PencilAltIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProviderClient } from "../../api/providers/client";
import Progressing from "../../components/atoms/Progressing";
import { isEmpty } from "../../utils/stringHelper";
import {
  rechargeAmountReceivedAdd,
  rechargeAmountSentAdd,
  rechargeOperatorIdAdd,
  rechargeOperatorNameAdd,
  rechargeTotalAmountAdd,
} from "../recharge/rechargeSlice";

import Operator from "./Operator";
import { operatorsSelected, operatorsSelectedByUid } from "./operatorsSlice";

const OperatorCollection = () => {
  const dispatch = useDispatch();
  const recharge = useSelector((state) => state.recharge);
  const operatorEntities = useSelector((state) => state.operators.entities);
  const operators = Object.values(operatorEntities);
  const [isLoading, setIsloading] = useState(true);

  const handleOperatorChange = () => {
    dispatch(rechargeOperatorIdAdd(""));
    dispatch(rechargeAmountReceivedAdd(""));
    dispatch(rechargeTotalAmountAdd(""));
    dispatch(rechargeAmountSentAdd(""));
  };

  const renderOperatorsList = operators.map((operator) => {
    return (
      <Operator
        key={Math.random().toString(36).substr(2, 9)}
        id={operator.operatorId}
      />
    );
  });

  useEffect(() => {
    async function bootstrapOperator() {
      setIsloading(true);

      if (recharge === undefined) {
        setIsloading(false);
        return;
      }
      if (isEmpty(recharge.receiverCountryCode)) {
        setIsloading(false);
        return;
      }
      dispatch(operatorsSelected(recharge?.operatorId));

      const providerClient = new ProviderClient(
        process.env.REACT_APP_PROVIDER_URL
      );

      if (isEmpty(recharge.receiverCountryCode) || isEmpty(recharge.phone))
        return;

      const looKupData = {
        countryisocode: recharge.receiverCountryCode,
        phone: recharge.phone,
        providerId: process.env.REACT_APP_RELOADLY_ID,
      };
      const operator = await providerClient.GetProviderByPhone(
        "/reloadly/autoDetectOperators",
        JSON.stringify(looKupData)
      );
      if (operator.status === "success" && operator.data.status === "success") {
        dispatch(operatorsSelectedByUid(operator.data.serviceId));
        dispatch(rechargeOperatorNameAdd(operator.data.name));
        dispatch(rechargeOperatorIdAdd(operator.data.operatorId));
      }
      setIsloading(false);
    }
    bootstrapOperator();
  }, [recharge.phone]);

  const showListOperators = () => {
    if (isLoading) {
      return (
        <div>
          <Progressing message="Detecting Operators" />
        </div>
      );
    }

    return (
      <div className="mt-10">
        <div className="flex flex-row justify-between mb-5">
          <h2 className="text-gray-700 text-lg font-semibold tracking-wide">
            Select Operator
          </h2>
          {recharge?.operatorId ? (
            <button
              onClick={handleOperatorChange}
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2  
                                      text-sm font-medium rounded text-blue-1000 bg-indigo-50 hover:bg-gray-100 focus:outline-none 
                                      focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <PencilAltIcon className="h-5 w-5 text-indigo-900" />
              <span className="text-xs md:text-base">Change</span>{" "}
            </button>
          ) : (
            ""
          )}
        </div>
        <ul className="mt-1 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {recharge?.operatorId ? (
            <Operator id={recharge?.operatorId} />
          ) : (
            renderOperatorsList
          )}
        </ul>
      </div>
    );
  };

  return recharge.phone && operators.length > 0 ? showListOperators() : "";
};

export default OperatorCollection;
