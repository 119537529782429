import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  EmailVerification,
  EmailVerified,
  ForgotPassword,
  Login,
  NewPassword,
  SignUp,
  Splash,
} from "../pages/Index";

import {
  EMAIL_VERIFICATION,
  EMAIL_VERIFIED,
  FORGOT_PASSWORD,
  LOGIN,
  NEW_PASSWORD,
  SIGNUP,
  SPLASH,
} from "./routeNames";

const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path={SPLASH}
        element={<Splash pageTitle="Splash" />}
        key="Splash"
      />
      <Route path={LOGIN} element={<Login />} />
      <Route path={SIGNUP} element={<SignUp />} />
      <Route path={`${EMAIL_VERIFIED}/:userID`} element={<EmailVerified />} />
      <Route path={EMAIL_VERIFICATION} element={<EmailVerification />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={NEW_PASSWORD} element={<NewPassword />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default PublicRouter;
