import {
  getFirestore,
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";

import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestorePaymentMethod {
  static async LoadByWalletId(walletId) {
    try {
      const pmCollection = [];
      const pmQuery = query(
        collection(db, `wallets/${walletId}/paymentMehods`)
      );

      const walletsSnapShot = await getDocs(pmQuery);

      walletsSnapShot.forEach((snapshot) => {
        pmCollection.push(snapshot.data());
      });
      return pmCollection;
    } catch (error) {
      return null;
    }
  }

  static async Add(pmData) {
    try {
      const newPMData = pmData;
      const pmRef = doc(
        collection(db, `wallets/${pmData.walletId}/paymentMehods`)
      );

      newPMData.uid = pmRef.id;
      newPMData.date = serverTimestamp();
      await setDoc(pmRef, { ...newPMData }, { merge: true });

      return newPMData;
    } catch (error) {
      return null;
    }
  }

  static async DeleteById(paymentMethod) {
    try {
      await deleteDoc(
        doc(
          db,
          "wallets",
          `${paymentMethod.walletId}/paymentMehods/${paymentMethod.uid}`
        )
      );
      return paymentMethod.uid;
    } catch (error) {
      return null;
    }
  }
}
