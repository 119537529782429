import React from "react";
import AuthEmail from "../../features/authentication/AuthEmail";

const SignUp = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="w-2/3">
        <AuthEmail />
      </div>
    </div>
  );
};
export default SignUp;
