import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import FirestoreUser from "../../api/firebase/firestore.user";
import { LOGIN } from "../../router/routeNames";
import { userAdd, userStatus } from "../user/userSlice";
import { USER_READY } from "../user/userStatus";

const AuthEmailVerified = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [message, setMessage] = useState();
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    if (params.userID === undefined) return;
    async function verifedEmail() {
      try {
        const verifiedUser = await FirestoreUser.Update({
          uid: params.userID,
          emailVerified: true,
        });

        if (verifiedUser === null) {
          setMessage(
            "We are unabled to verified email. Contact support@jlrecharge.com"
          );
          setEmailVerified(false);
          return;
        }

        dispatch(userAdd(verifiedUser));
        dispatch(userStatus(USER_READY));
        setMessage(
          "Your email was verified with success. Continue your onboard process. "
        );
        setEmailVerified(true);
      } catch (error) {
        setMessage(
          "We are unabled to verified email. Contact support@jlrecharge.com"
        );
        setEmailVerified(false);
      }
    }

    verifedEmail();
  }, []);

  return (
    <div className="flex justify-center align-middle content-center">
      <div className="py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white rounded-2xl shadow-sm">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-row justify-center">
            <h2 className="mx-6 my-2 text-2xl font-extrabold text-gray-900">
              Email Verification
            </h2>
          </div>

          <div className="mt-8">
            <div className="flex flex-col">
              <div className="mb-6 flex justify-center">
                {emailVerified ? (
                  <CheckCircleIcon className="text-blue-1000 h-24 w-24" />
                ) : (
                  <ExclamationCircleIcon className="text-red-600 h-24 w-24" />
                )}
              </div>
              <div className="flex flex-col justify-center">
                <h2 className="font-semibold mb-6 text-center text-lg">
                  {emailVerified ? "Email Verified" : "Failed"}
                </h2>
                <p className="text-gray-500 mb-6 text-center">{message}</p>
              </div>

              <div className="mb-6">
                <Link
                  to={LOGIN}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                                 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthEmailVerified;
