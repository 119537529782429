/**
 * concact Calling Code And Phone - Country Code + Phone Number
 *
 * Args:
 *      country code (string): phone indicative for a specific country
 *      phone (string): phone nnumber that will be credit *
 * Returns:
 *      (String): phone numer with country code
 *
 */
export const concactCallingCodeAndPhone = (countryCallingCode, phone) => {
  if (phone === null || phone === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  if (countryCallingCode === null || countryCallingCode === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  // Phone already has country code.
  if (phone.includes(countryCallingCode)) return phone;

  phone = phone.trim().replace("+", "");

  return `${countryCallingCode.trim()}${phone}`;
};
