/**
 * Convert particular currency ( amount) using a specific rate
 *
 * Args:
 *      amount (Number): The amount to be converted into another currency.
 *      rate (Number): The rate that is going to be used for the conversion.
 *
 * Returns:
 *      (String): Amount Converted
 *
 */
export const applyRate = (amount, rate) => {
  if (rate === null || rate === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  if (amount === null || amount === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  // Example: USD - CVE
  // amount = (amount CVE * 1 USD) / rates CVE

  let amountConverted = Number(amount) / Number(rate);

  // Two Digit
  return amountConverted;
};

/**
 * Get Rate Value based Destination Country.
 *
 * Args:
 *      rates (Object): List of the Rates Indexed by Country Code
 *      country code (String): The country code need to extract rate value
 *
 * Returns:
 *      (Number): Rate Value
 *
 */

export const applyRateToReceiver = (amount, rate) => {
  if (rate === null || rate === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  if (amount === null || amount === undefined) {
    throw new Error(
      "Missing Inforamtion - Contact support: support@jlrecharge.com"
    );
  }

  // Example: USD - CVE
  // amount = (amount US * Amount CVE) * rates CVE

  let amountConverted = Number(amount) * Number(rate);

  // Two Digit
  return amountConverted;
};
