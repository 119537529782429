import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import FirestoreOperators from "../../api/firebase/firestore.operators";
import {
  OPERATORS_LOADING,
  OPERATORS_LOAD_FAILED,
  OPERATORS_LOAD_SUCESS,
  OPERATORS_UNKNOWN,
} from "./operatorState";

const operatorsAdapter = createEntityAdapter({
  selectId: (operator) => operator.operatorId,
});

const initialState = operatorsAdapter.getInitialState({
  status: OPERATORS_UNKNOWN,
});

export const loadOperatorsByCountry = createAsyncThunk(
  "operators/LoadByCountry",
  async (countryReceiver) => {
    const operators = await FirestoreOperators.LoadByCountry(countryReceiver);
    return operators;
  }
);

export const operatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    operatorsStatus(state, action) {
      state.status = action.payload;
    },
    operatorsAddMany: operatorsAdapter.addMany,
    operatorsSetAll: operatorsAdapter.setAll,
    operatorsAddOne: operatorsAdapter.addOne,
    operatorsSelected(state, action) {
      const operatorId = action.payload;
      Object.values(state.entities).forEach((operator) => {
        operator.selected =
          operator.operatorId === operatorId ? !operator.selected : false;
      });
    },
    operatorsSelectedByUid(state, action) {
      const uid = action.payload;
      Object.values(state.entities).forEach((operator) => {
        operator.selected = operator.uid === uid ? !operator.selected : false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOperatorsByCountry.pending, (state) => {
        state.status = OPERATORS_LOADING;
      })
      .addCase(loadOperatorsByCountry.rejected, (state) => {
        state.status = OPERATORS_LOAD_FAILED;
      })
      .addCase(loadOperatorsByCountry.fulfilled, (state, action) => {
        state.status = OPERATORS_LOAD_SUCESS;
        operatorsAdapter.setAll(state, action.payload);
      });
  },
});

export const {
  operatorsStatus,
  operatorsAddMany,
  operatorsSetAll,
  operatorsAddOne,
  operatorsSelected,
  operatorsSelectedByUid,
} = operatorsSlice.actions;

export default operatorsSlice.reducer;

export const { selectAll: selectOperators, selectById: selectOperatorById } =
  operatorsAdapter.getSelectors((state) => state.operators);

export const selectOperatorsIds = createSelector(selectOperators, (operators) =>
  operators.map((operator) => operator.operatorId)
);

export const selectedOperators = createSelector(
  // First Argument: Collections Operators
  selectOperators,

  // Second functions Argument: Operator ID
  (_, operatorId) => operatorId,

  (operators, operatorId) =>
    operators.filter(
      (operator) =>
        operator.selected === true || operator.operatorId === operatorId
    )[0]
);
