import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import {
  TRANSACTIONS_UNKNOWN,
  TRANSACTION_PROCESSING,
  TRANSACTION_PROCESSING_COMPLETE,
  TRANSACTION_PROCESSING_FAILED,
} from "./transactionState";

const transactionsAdapter = createEntityAdapter({
  selectId: (transaction) => transaction.uid,
});

const initialState = transactionsAdapter.getInitialState({
  status: TRANSACTIONS_UNKNOWN,
});

export const transactionsAdd = createAsyncThunk(
  "transactions/Add",
  async (transactionData) => {
    const transaction = await FirestoreTransactions.Create(transactionData);
    return transaction;
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    transactionsStatus(state, action) {
      state.status = action.payload;
    },
    transactionsAddMany: transactionsAdapter.addMany,
    transactionsAddOne: transactionsAdapter.addOne,
    transactionsSetAll: transactionsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(transactionsAdd.pending, (state) => {
        state.status = TRANSACTION_PROCESSING;
      })
      .addCase(transactionsAdd.rejected, (state) => {
        state.status = TRANSACTION_PROCESSING_FAILED;
      })
      .addCase(transactionsAdd.fulfilled, (state, action) => {
        state.status = TRANSACTION_PROCESSING_COMPLETE;
        transactionsAdapter.addOne(state, action.payload);
      });
  },
});

export const {
  transactionsAddMany,
  transactionsAddOne,
  transactionsStatus,
  transactionsSetAll,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;

export const {
  selectAll: selectTransactions,
  selectById: selectTransactionById,
} = transactionsAdapter.getSelectors((state) => state.transactions);

export const selectTransactionsIds = createSelector(
  selectTransactions,
  (transactions) => transactions.map((transaction) => transaction.uid)
);

export const selectTransactionsByBusinessId = createSelector(
  // All Transactions
  selectTransactions,

  // BusinessId
  (_, businessId) => businessId,

  (transactions, businessId) =>
    transactions.filter((transaction) => transaction.businessId === businessId)
);
