import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { executeWalletAutoRecharge } from "../../utils/executeWalletAutoRecharge";
import {
  walletTitleAdd,
  walletUpdate,
  walletsStatus,
} from "../../features/wallets/walletSlice";
import {
  WALLETS_FUND_ADD_FAILED,
  WALLETS_READY,
  WALLET_RECHARGE_FAILED,
} from "../../features/wallets/walletStatus";
import { WALLET_RECHARGE } from "../../router/routeNames";
import { SUCCESS } from "../../api/http";

const withWallet = (Component) => () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);
  const stripe = useStripe();

  useEffect(() => {
    async function HandleAutoRecharge() {
      const response = await executeWalletAutoRecharge(wallet, user, stripe);
      if (response.status === SUCCESS) {
        dispatch(walletUpdate(response.wallet));
        dispatch(walletsStatus(WALLETS_READY));
        return;
      }

      dispatch(walletTitleAdd("Auto Recharge Failed"));
      dispatch(walletsStatus(WALLETS_FUND_ADD_FAILED));
      if (!response || response == WALLET_RECHARGE_FAILED) {
        navigate(WALLET_RECHARGE);
      }
    }
    HandleAutoRecharge();
  }, []);
  return (
    <>
      <Component />
    </>
  );
};

export default withWallet;
