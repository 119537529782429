import React from "react";
import { useSelector } from "react-redux";
import { selectPaymentMethodsId } from "./paymentMethodsSlice";
import PaymentMethodDebitCard from "./PaymentMethodDebitCard";
import { ROLES } from "../authentication/models";
import NoPaymentMethod from "./NoPaymentMethod";
import PaymentMethod from "./PaymentMethod";
import AddPaymentMethodCard from "./AddPaymentMethodCard";

const PaymentMethodCollection = () => {
  const user = useSelector((state) => state.user);
  const paymentMethodCollectionIds = useSelector((state) =>
    selectPaymentMethodsId(state)
  );

  const renderPaymentMethodCollections =
    paymentMethodCollectionIds &&
    paymentMethodCollectionIds.map((paymentMethodId) => {
      if (
        user?.data !== undefined &&
        (user?.data?.roles[ROLES.manager] || user?.data?.roles[ROLES.partner])
      ) {
        return <PaymentMethod id={paymentMethodId} key={paymentMethodId} />;
      }
      return (
        <PaymentMethodDebitCard id={paymentMethodId} key={paymentMethodId} />
      );
    });

  const renderPaymentMethodList = () => {
    return (
      <div className="overflow-hidden rounded-lg border-none border-white">
        <div className="mt-3 grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
          {renderPaymentMethodCollections}
          <AddPaymentMethodCard />
        </div>
      </div>
    );
  };

  return paymentMethodCollectionIds !== undefined &&
    paymentMethodCollectionIds.length > 0 ? (
    renderPaymentMethodList()
  ) : (
    <NoPaymentMethod
      title="Add Payment Method"
      description="Add Payment Method to Recharge your Wallet."
    />
  );
};

export default PaymentMethodCollection;
