import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { businessAdd } from "./businessSlice";
import { isEmpty } from "../../utils/stringHelper";
import ComboBoxesBase from "../../components/atoms/ComboBoxesBase";
import { selectedCountryByIsoName } from "../countries/countriesSlice";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import { BUSINESS } from "../../router/routeNames";
import { Link } from "react-router-dom";

const BusinessForm = () => {
  const dispatch = useDispatch();

  const countriesEntities = useSelector((state) => state.countries.entities);
  const countries = Object.values(countriesEntities);

  const user = useSelector((state) => state.user);
  const selectedCountry = useSelector((state) =>
    selectedCountryByIsoName(state, user?.data?.countryCode)
  );

  const wallet = useSelector((state) => state.wallet);
  const [error, setError] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [country, setCountry] = useState(selectedCountry);
  const [postalCode, setPostalCode] = useState(user.data?.postalCode);
  const [street, setStreet] = useState("");
  const [stateValue, setStateValue] = useState(user.data?.state);
  const [city, setCity] = useState(user.data?.city);

  const onHandleSelectCountry = (data) => {
    setCountry(data);
  };

  const handleSaveBusiness = () => {
    if (isEmpty(businessName)) {
      setError("Business Name is required!");
      return;
    }
    if (isEmpty(country.name)) {
      setError("Country Name is required!");

      return;
    }
    if (isEmpty(city)) {
      setError("City is required!");
      return;
    }
    if (isEmpty(street)) {
      setError("Street is required!");
      return;
    }

    if (isEmpty(postalCode)) {
      setError("Zip Code is required!");
      return;
    }
    const businessData = {
      ownerId: user?.data?.uid,
      name: businessName,
      city: city,
      street: street,
      countryName: country.name,
      zipCode: postalCode,
      state: stateValue,
      isApproved: false,
      walletId: wallet?.data?.uid || user?.data?.uid,
      countryCode: user?.data?.countryCode,
    };
    dispatch(businessAdd(businessData));
    setError("");
  };

  return (
    <div className="px-8 pb-5">
      <div className="flex flex-row justify-between">
        <h2 className="text-gray-700 text-2xl font-semibold tracking-wide">
          Business Information
        </h2>
      </div>
      <div className="mt-4 py-5 rounded-lg">
        <p className="text-red-600 text-sm">{error}</p>

        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Business Name
              </dt>
              <input
                onChange={(data) => setBusinessName(data.target.value)}
                value={businessName}
                placeholder="Ex: JLRCharge Space"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                type="text"
              ></input>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Street Address
              </dt>
              <input
                onChange={(data) => setStreet(data.target.value)}
                value={street}
                placeholder="Ex: 982 Main Street"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                type="text"
              ></input>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <input
                onChange={(data) => setStateValue(data.target.value)}
                value={stateValue}
                placeholder="Ex: MA"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                type="text"
              ></input>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">City</dt>
              <input
                onChange={(data) => setCity(data.target.value)}
                value={city}
                placeholder="Ex: Boston"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                type="text"
              ></input>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
              <input
                onChange={(data) => setPostalCode(data.target.value)}
                value={postalCode}
                placeholder="Ex: 02301"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                type="text"
              ></input>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Country</dt>

              {countries.length > 0 ? (
                <ComboBoxesBase
                  selectOptions={countries}
                  selectedOption={country || selectedCountry}
                  title=""
                  handleSelect={onHandleSelectCountry}
                />
              ) : (
                <></>
              )}
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:px-6 flex flex-row justify-end">
          <Link
            to={BUSINESS}
            className="text-center w-44 px-6 py-3 border-2 border-gray-50 shadow-sm text-base font-medium rounded-md text-blue-1000
                                 bg-white hover:bg-gray-25 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
          >
            Cancel
          </Link>

          <button
            type="button"
            onClick={handleSaveBusiness}
            className="inline-flex  items-center px-5 py-3 border border-transparent shadow-sm 
						text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 
						focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <OfficeBuildingIcon
              className="mr-2 -ml-1 h-4 w-4"
              aria-hidden="true"
            />
            Add Business
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
