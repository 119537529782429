import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import Log from "../../models/log";

import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreWallets {
  static async GetById(userId, businessId = "") {
    try {
      const docRef = doc(db, "wallets", userId);
      const docSnap = await getDoc(docRef);

      // IF Wallet Exist - Return.
      if (docSnap.exists()) {
        return docSnap.data();
      }
      // Create User Wallet
      const walletRef = doc(db, "wallets", userId);
      const walletData = {
        amount: 0,
        userId: userId,
        paymentMethodId: "",
        balanceAmount: 400,
        minBalance: 100,
        businessId,
        minBalanceOptions: {
          0: 100,
          2: 150,
          3: 250,
        },
        balanceAmountOptions: {
          0: 100,
          2: 200,
          3: 400,
          4: 600,
          5: 800,
          6: 1000,
        },
        autoRecharge: false,
      };
      await setDoc(walletRef, walletData, { merge: true });
      return walletData;
    } catch (error) {
      return null;
    }
  }

  static async GetByBusinessId(businessId) {
    try {
      const walletsCollection = [];
      const queryWallet = query(
        collection(db, "wallets"),
        where("businessId", "==", businessId)
      );

      const walletsSnapShot = await getDocs(queryWallet);

      walletsSnapShot.forEach((snapshot) => {
        walletsCollection.push(snapshot.data());
      });
      return walletsCollection.length > 0 ? walletsCollection[0] : null;
    } catch (error) {
      return null;
    }
  }

  static async PaymentMethodAdd(wallet) {
    try {
      const ref = doc(db, "wallets", wallet.uid);
      await setDoc(ref, wallet, { merge: true });
      return await FirestoreWallets.GetById(wallet.uid);
    } catch (error) {
      const log = new Log({
        message: error.message || "Failed Add Payment Method",
        exception: error.message || error,
        userId: wallet.userId,
        action: "JLRSpace.FirestoreWallets.PaymentMethodAdd",
      });
      return log;
    }
  }

  static async SetDefatultPaymentMethod(wallet) {
    try {
      const ref = doc(db, "wallets", wallet.uid);
      await setDoc(ref, wallet, { merge: true });
      return await FirestoreWallets.GetById(wallet.uid);
    } catch (error) {
      const log = new Log({
        message: error.message || "Failed Set Defatult Payment Method",
        exception: error.message || error,
        userId: wallet.userId,
        action: "JLRSpace.FirestoreWallets.SetDefatultPaymentMethod",
      });
      return log;
    }
  }

  static async Update(wallet) {
    try {
      const ref = doc(db, "wallets", wallet.uid);
      await setDoc(ref, { ...wallet }, { merge: true });
      return await FirestoreWallets.GetById(wallet.uid);
    } catch (error) {
      return null;
    }
  }
}
