import { CreditCardIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";
import { WALLET_ADD_PAYMENTMETHOD } from "../../router/routeNames";

const AddPaymentMethodCard = () => {
  return (
    <Link
      to={WALLET_ADD_PAYMENTMETHOD}
      className="bg-white w-full rounded-lg border-2 border-dashed border-gray-300 flex justify-center"
    >
      <div className="flex-shrink-0 flex items-center justify-center w-20 text-blue-1000 rounded-l-md p-4">
        <CreditCardIcon className="text-blue-1000" />
      </div>
      <span
        className="relative items-center flex text-blue-1000 text-lg font-semibold
                     hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Add Payment Method
      </span>
    </Link>
  );
};

export default AddPaymentMethodCard;
