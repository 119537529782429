import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../utils/stringHelper";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import FirestoreOperators from "../../api/firebase/firestore.operators";
import { operatorsAddMany, operatorsStatus } from "../operators/operatorsSlice";
import { OPERATORS_LOAD_SUCESS } from "../operators/operatorState";
import { filterOperatorIdAdd } from "./filterSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OperatorFilter = () => {
  const dispatch = useDispatch();
  const operatorsEntities = useSelector((state) => state.operators.entities);
  const operators = Object.values(operatorsEntities);
  const [operatorSelected, setOperatorSelected] = useState({});

  const [query, setQuery] = useState("");

  const filteredOperators = isEmpty(query)
    ? operators
    : operators.filter((operator) => {
        return operator.name.toLowerCase().includes(query.toLowerCase());
      });

  const setOperatorFilter = (data) => {
    setOperatorSelected(data);
    dispatch(filterOperatorIdAdd(data.operatorId));
  };

  useEffect(() => {
    async function loadCountries() {
      const operators = await FirestoreOperators.LoadByCountry("CV");
      if (operators !== undefined && operators.length > 0) {
        dispatch(operatorsAddMany(operators));
        dispatch(operatorsStatus(OPERATORS_LOAD_SUCESS));
      }
    }
    loadCountries();
  }, []);

  return (
    <Combobox
      as="div"
      className="mx-2"
      value={operatorSelected}
      onChange={setOperatorFilter}
    >
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-2xl border border-gray-300 bg-white p-4 shadow-sm focus:border-indigo-500 
                      focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm font-medium text-gray-500"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(operator) => operator?.name || "Select Operator"}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredOperators.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOperators.map((operator) => (
              <Combobox.Option
                key={operator.uid}
                value={operator}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={operator.logo}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {operator.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default OperatorFilter;
