export const AuthCode = {
  SUCCESS: "success",
  FAILED: "failed",
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASSWORD: "auth/wrong-password",
};

export const AuthMessage = {
  "auth/user-not-found": "User not found.",
  "auth/wrong-password": "Invalid Password",
  "auth/email-already-in-use": "Email already in use",
  "auth/invalid-credential": "Invalid Credentials",
  "auth/invalid-email": "Invalid Email",
  "auth/account-exists-with-different-credential":
    "Account already exisit with different credential",
  "auth/invalid-phone-number": "Invalid phone number",
  "auth/phone-number-already-exists": "Phone number already exist",
};

export const ROLES = {
  admin: "admin",
  partner: "partner", // Business Location work directly with JLRecharge
  agent: "agent", // Business Location working with Manager
  manager: "manager", // JLRecharge distrutor that has many agents
  provider: "provider", // JlRecharge business partner who sells credit in our platform.
  employee: "employee", // JLRSpace Employee who work for JLRSpace partner
};

export const getRole = (roles) => {
  if (roles === undefined) {
    throw new Error(
      "Roles can not be null.  Contact support: support@jlrecharge.com"
    );
  }

  if (roles.length <= 0) {
    throw new Error(
      "User has to have role.  Contact support: support@jlrecharge.com"
    );
  }

  if (roles[ROLES.admin]) {
    return "ADMIN";
  } else if (roles[ROLES.partner]) {
    return "PARTNER";
  } else if (roles[ROLES.agent]) {
    return "AGENT";
  } else if (roles[ROLES.manager]) {
    return "MANAGER";
  } else if (roles[ROLES.employee]) {
    return "EMPLOYEE";
  }
};

export class AuthData {
  constructor(data) {
    this.email = data.email;
    this.password = data.password;
  }
}

export class AuthResponse {
  constructor(response) {
    if (response.status === AuthCode.FAILED) {
      this.status = response.status || AuthCode.SUCCESS;
      this.message = response.message || "";
      this.code = response.code | "";
      return;
    }
    this.uid = response.uid;
    this.email = response?.email;
    this.emailVerified = response?.emailVerified;
    this.phoneNumber = response?.phoneNumber || "";
    this.displayName = response?.displayName || "";
    this.photoURL = response?.photoURL || "";
    this.roles = response.roles || "";
    this.status = response.status || AuthCode.SUCCESS;
  }
}
