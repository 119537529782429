// eslint-disable-next-line no-unused-vars
import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authenticationReducer from "../features/authentication/authenticationSlice";
import transactionsReducer from "../features/transactions/transactionsSlice";
import operatorsReducer from "../features/operators/operatorsSlice";
import walletReducer from "../features/wallets/walletSlice";
import filtersSliceReducer from "../features/filters/filterSlice";
import userReducer from "../features/user/userSlice";
import rechargeReducer from "../features/recharge/rechargeSlice";
import countriesReducer from "../features/countries/countriesSlice";
import ratesReducer from "../features/rates/ratesSlice";
import settingsReducer from "../features/settings/settingsSlice";
import paymentMethodsReducer from "../features/paymentMethods/paymentMethodsSlice";
import businessReducer from "../features/business/businessSlice";
import paymentReducer from "../features/payments/paymentsSlice";

const combinedReducers = combineReducers({
  authentication: authenticationReducer,
  transactions: transactionsReducer,
  operators: operatorsReducer,
  wallet: walletReducer,
  filters: filtersSliceReducer,
  user: userReducer,
  countries: countriesReducer,
  recharge: rechargeReducer,
  rates: ratesReducer,
  settings: settingsReducer,
  paymentMethods: paymentMethodsReducer,
  businesses: businessReducer,
  payments: paymentReducer,
});

const resetTableRootReducer = (state, action) => {
  if (action.type === "authentication/logoOut") {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  name: "jlrspace",
  reducer: resetTableRootReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
