import React from "react";
import { useSelector } from "react-redux";
import { AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT } from "../../features/authentication/authState";
import EmailResentPasswordSent from "../../features/authentication/EmailResentPasswordSent";
import SendEmailResetPassword from "../../features/authentication/SendEmailResetPassword";

const ForgotPassword = () => {
  const authentication = useSelector((state) => state.authentication);
  return (
    <div className="flex flex-col min-h-screen justify-center">
      {authentication.status === AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT ? (
        <EmailResentPasswordSent />
      ) : (
        <SendEmailResetPassword />
      )}
    </div>
  );
};
export default ForgotPassword;
