import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreOperators {
  static async LoadByCountry(countryIso) {
    try {
      const operatorCollection = [];
      const queryOperators = query(
        collection(db, "operators"),
        where("country.isoName", "==", countryIso)
      );

      const operatorsSnapShot = await getDocs(queryOperators);
      if (operatorsSnapShot === null || operatorsSnapShot.length === 0) {
        return operatorCollection;
      }

      operatorsSnapShot.forEach((snapshot) => {
        operatorCollection.push(snapshot.data());
      });
      return operatorCollection;
    } catch (error) {
      return null;
    }
  }
}
