import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { authenticationRoleAdd } from "../authentication/authenticationSlice";

import { useNavigate } from "react-router-dom";
import { PuzzleIcon } from "@heroicons/react/outline";
import { setRole } from "./userSlice";
import { ROLES } from "../authentication/models";
import { DASHBOARD } from "../../router/routeNames";

const roles = [
  {
    name: "partner",
    displayName: "Partner",
    description:
      "Full Access to JLRecharge to provide our service to your customer.",
  },
  {
    name: "manager",
    displayName: "Manager",
    description:
      "Full Access to JLRSpace to manage your agents who provide our service to their customers.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserRoleOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const [selected, setSelected] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const SaveRole = () => {
    if (selected === undefined) {
      setErrorMessage("Please select your role to join JLRSpace");
      return;
    }
    setErrorMessage("");
    dispatch(
      setRole({
        uid: user?.data?.uid,
        isActive: false,
        roles: {
          partner: selected.name === ROLES.partner,
          manager: selected.name === ROLES.manager,
          agent: false,
          employee: false,
        },
      })
    );
  };

  const handleSelectRole = (role) => {
    dispatch(authenticationRoleAdd(role.name));
    setSelected(role);
  };

  useEffect(() => {
    if (user === undefined) return;
    if (user?.data === undefined) return;
    if (user?.data?.roles !== undefined) {
      navigate(DASHBOARD, { replace: true });
      return;
    }
  }, [user?.data?.roles]);

  return (
    <div>
      <div className="w-full flex justify-center">
        <PuzzleIcon className="text-blue-1000 h-24 w-24" />
      </div>
      <RadioGroup value={selected} onChange={handleSelectRole} className="mt-5">
        <RadioGroup.Label className="text-3xl font-bold flex justify-center mb-5">
          How do you want Join JLRSpace
        </RadioGroup.Label>
        <span className="text-base text-gray-700 font-medium justify-center flex">
          Choose how would like to join JLRSpace.
        </span>

        <div className="space-y-4 mt-10">
          <span className="text-base font-bold justify-center flex text-red-700 mt-">
            {errorMessage}
          </span>
          {roles.map((role) => (
            <RadioGroup.Option
              key={role.displayName}
              value={role}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center">
                    <span className="text-sm flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="font-semibold text-blue-1000 text-lg"
                      >
                        {role.displayName}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="text-gray-500"
                      >
                        <span className="block sm:inline">
                          {role.description}
                        </span>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-indigo-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="w-full flex justify-center mt-10">
        <button
          type="button"
          onClick={SaveRole}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default UserRoleOptions;
