import React from "react";
import PropTypes from "prop-types";
const BalanceOptions = ({
  title,
  balanceAmount,
  handleBalanceAmount,
  balanceAmountOptions,
}) => {
  const renderBalanceOptions = Object.values(balanceAmountOptions).map(
    (balanceAmountOption) => {
      return (
        <button
          key={balanceAmountOption}
          type="button"
          className={`mt-5 relative inline-flex items-center px-4 py-2 rounded border mx-1
                            ${
                              balanceAmount === balanceAmountOption
                                ? "bg-blue-1000 text-white border-blue-1000"
                                : "bg-white text-gray-700 border-gray-300 "
                            }
                            text-sm font-medium 
                         hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
          onClick={() => handleBalanceAmount(balanceAmountOption)}
        >
          {balanceAmountOption}
        </button>
      );
    }
  );

  return (
    <>
      <h3 className="mt-5 font-semibold text-lg">{title}</h3>
      {renderBalanceOptions}
    </>
  );
};

BalanceOptions.propTypes = {
  handleBalanceAmount: PropTypes.func.isRequired,
  balanceAmount: PropTypes.number.isRequired,
  balanceAmountOptions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};
export default BalanceOptions;
