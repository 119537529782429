import React from "react";

const UpdatePassword = () => {
  return (
    <div className="flex justify-center align-middle content-center">
      UpdatePassword
    </div>
  );
};

export default UpdatePassword;
