import React from "react";
import { ROLES } from "../authentication/models";
import PropTypes from "prop-types";

const RechargeDetails = ({ user, recharge }) => {
  return (
    <div className="p-8">
      <p className="text-lg md:text-2xl text-gray-700 font-semibold h-12">
        Order Details
      </p>
      <div className="flex flex-col">
        <div className="mt-2 border-2 border-dashed border-gray-100 p-3 rounded-md">
          <div className="flex lg:flex-row justify-between mb-3 lg:mb-0">
            <p className="text-sm md:text-base text-gray-700 font-semibold">
              Country:
            </p>
            <p className="text-sm md:text-base text-gray-700">
              {recharge.receiverCountryName}
            </p>
          </div>
          <div className="flex lg:flex-row justify-between mb-3 lg:mb-0">
            <div className="text-sm md:text-base text-gray-700 font-semibold">
              Phone:
            </div>
            <div className="text-sm md:text-base text-gray-700">
              {recharge.phone}
            </div>
          </div>
          <div className="flex lg:flex-row justify-between">
            <p className="text-sm md:text-base text-gray-700 font-semibold">
              Operator:
            </p>
            <p className="text-sm md:text-base text-gray-700">
              {recharge.operatorName}
            </p>
          </div>
        </div>

        <div className="mt-2 border-2 border-dashed border-gray-100 p-3 rounded-md">
          <div className="flex lg:flex-row justify-between mb-3 lg:mb-0">
            <p className="text-sm md:text-base text-gray-700 font-semibold">
              Amount Received:
            </p>
            <p className="text-sm md:text-base text-gray-700">
              ${recharge.amountReceived} {recharge.receiverCurrencyCode}
            </p>
          </div>

          <div className="flex flex-row justify-between">
            <p className="text-sm md:text-base text-gray-700 font-semibold">
              Fees:
            </p>
            <p className="text-sm md:text-base text-gray-700">
              $ {recharge.fees} {user?.data?.currencyCode}
            </p>
          </div>
          {user.data?.roles[ROLES.manager] ||
          user.data?.roles[ROLES.partner] ? (
            <div className="flex lg:flex-row justify-between mb-3 lg:mb-0 border-t-2 border-gray-100 border-solid mt-3 pt-2">
              <p className="text-sm md:text-base text-green-600 font-semibold">
                Commission
              </p>
              <p className="text-sm md:text-base text-green-600 font-bold">
                ${recharge.userCommission} {user?.data?.currencyCode}
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="flex flex-row justify-between">
            <p className="text-sm md:text-base text-blue-1000 font-semibold">
              Total Cost:
            </p>
            <p className="text-sm md:text-base text-blue-1000 font-bold">
              ${recharge.totalAmount} {user?.data?.currencyCode}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

RechargeDetails.propTypes = {
  user: PropTypes.any.isRequired,
  recharge: PropTypes.any.isRequired,
};
export default RechargeDetails;
