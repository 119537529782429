import { CreditCardIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentMethodById } from "./paymentMethodsSlice";
import PropTypes from "prop-types";
import { ROLES } from "../authentication/models";
import Toggle from "../../components/atoms/Toggle";
import {
  walletPaymentMethodDefault,
  walletsStatus,
} from "../wallets/walletSlice";
import { WALLETS_READY } from "../wallets/walletStatus";
import { Link } from "react-router-dom";
import { WALLET_PAYMENT_METHOD_DELETE } from "../../router/routeNames";

const PaymentMethod = ({ id }) => {
  const dispatch = useDispatch();

  const paymentMethod = useSelector((state) =>
    selectPaymentMethodById(state, id)
  );
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);

  const [pmDefatul, setPmDefault] = useState(
    paymentMethod.paymentMethodId === wallet?.data?.paymentMethodId
  );

  if (paymentMethod === undefined) {
    return <div>No Payment Method Selected.</div>;
  }

  const makePmDefault = () => {
    const pm = {
      uid: user?.data?.uid,
      paymentMethodId: paymentMethod.paymentMethodId,
    };
    dispatch(walletPaymentMethodDefault(pm));
    dispatch(walletsStatus(WALLETS_READY));
    setPmDefault(!pmDefatul);
  };
  return (
    <div
      key={paymentMethod.uid}
      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white"
    >
      <div className="flex w-full items-center justify-between space-x-10 p-4">
        <div className="flex-shrink-0 flex items-center justify-center w-20 text-blue-1000 rounded-l-md p-4">
          <CreditCardIcon className="text-blue-1000" />
        </div>
        <div className="flex flex-col truncate">
          <div className="flex items-center space-x-4">
            <h3 className="truncate text-lg font-medium text-gray-900">
              {paymentMethod.brand?.toUpperCase()} ....
            </h3>
            <span className="inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-lg font-medium text-blue-1000">
              {paymentMethod.last4}
            </span>
          </div>
          <p className="mt-1 truncate text-sm text-gray-500">
            {paymentMethod.expMonth}/{paymentMethod.expYear}
          </p>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1 p-1">
            <div className="flex items-center">
              {user?.data.roles[ROLES.agent] ? (
                ""
              ) : (
                <Toggle isActive={pmDefatul} onClick={makePmDefault} />
              )}
            </div>
          </div>
          <div className="-ml-px flex w-0 flex-1 divide-x divide-gray-200">
            <Link
              to={WALLET_PAYMENT_METHOD_DELETE(paymentMethod.uid)}
              className="relative inline-flex w-0 flex-1 items-center justify-center py-4 text-sm font-semibold text-gray-800 hover:text-gray-500"
            >
              <TrashIcon className="h-5 w-5 text-red-700" aria-hidden="true" />
              <span className="ml-1">Delete</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  id: PropTypes.string.isRequired,
};
export default PaymentMethod;
