import React from "react";
import { TrendingUpIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { StatsTypeIcons } from "./constants";

const TransactionStats = ({ title, data, type }) => {
  const StatsIcon = StatsTypeIcons[type];

  return (
    <div className="relative bg-white mb-5 pt-5 px-4  sm:pt-6 sm:px-6 rounded-lg overflow-hidden">
      <div className="flex flex-col">
        <div>
          <StatsIcon
            className="h-6 w-6 text-blue-1000 mb-2"
            aria-hidden="true"
          />
        </div>
        <div className="flex flex-row">
          <p className="text-black text-3xl font-semibold mr-1">{data}</p>
          <TrendingUpIcon
            className="h-6 w-6 text-blue-1000"
            aria-hidden="true"
          />
        </div>
        <div className="mb-4 text-base">{title}</div>
      </div>
    </div>
  );
};

TransactionStats.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};
export default TransactionStats;
