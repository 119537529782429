export const UNKNOWN = "UNKNOWN";
export const PAYMENTMETHODS_LOADED_PENDING = "payment_methods_loaded_pending";
export const PAYMENTMETHODS_LOADED_FAILED = "payment_methods_loaded_failed";
export const PAYMENTMETHODS_LOADED_DONE = "payment_methods_loaded_done";

export const PAYMENTMETHODS_ADD_PENDING = "payment_methods_add_pending";
export const PAYMENTMETHODS_ADD_FAILED = "payment_methods_add_failed";
export const PAYMENTMETHODS_ADD_DONE = "payment_methods_add_done";

export const PAYMENTMETHODS_DELETE_PENDING = "payment_methods_delete_pending";
export const PAYMENTMETHODS_DELETE_FAILED = "payment_methods_delete_failed";
export const PAYMENTMETHODS_DELETE_DONE = "payment_methods_delete_done";

export const PAYMENTMETHODS_READY = "payment_methods_ready";
