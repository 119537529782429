import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  businessUpdate,
  businessUpdateOne,
  selectBusinessById,
  selectBusinessSelected,
} from "./businessSlice";
import { getRole } from "../authentication/models";
import { BUSINESS_DETAILS } from "../../router/routeNames";

import { BriefcaseIcon } from "@heroicons/react/outline";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import {
  transactionsAddMany,
  transactionsStatus,
} from "../transactions/transactionsSlice";
import { TRANSACTION_PROCESSING_COMPLETE } from "../transactions/transactionState";

const BusinessItem = ({ id }) => {
  const dispatch = useDispatch();
  const business = useSelector((state) => selectBusinessById(state, id));
  const businessSelected = useSelector((state) =>
    selectBusinessSelected(state)
  );
  const user = useSelector((state) => state.user);
  const roleName = getRole(user?.data?.roles);

  const [transactions, setTransactions] = useState([]);

  const selectBusiness = () => {
    const selectedBusinessData = {
      ...businessSelected,
      selected: false,
    };

    // Basicaly Unselected Select Business
    dispatch(businessUpdate(selectedBusinessData));

    dispatch(
      businessUpdateOne({
        id: selectedBusinessData.uid,
        changes: selectedBusinessData,
      })
    );

    // Select new Business
    const selectNewBusiness = {
      ...business,
      selected: true,
    };
    dispatch(businessUpdate(selectNewBusiness));

    dispatch(
      businessUpdateOne({
        id: selectNewBusiness.uid,
        changes: selectNewBusiness,
      })
    );
  };

  useEffect(() => {
    async function loadBusinessData() {
      if (business === undefined) return;
      if (business.uid === undefined) return;

      const trans = await FirestoreTransactions.LoadByBusinessId(business.uid);
      if (trans !== null && trans.length > 0) {
        setTransactions(trans);
        dispatch(transactionsAddMany(trans));
        dispatch(transactionsStatus(TRANSACTION_PROCESSING_COMPLETE));
      }
    }

    loadBusinessData();
  }, [business]);

  return (
    <button
      type="button"
      onClick={selectBusiness}
      key={business.uid}
      className={`col-span-1 flex flex-col text-center bg-white rounded-lg ${
        business.selected
          ? "border-2 border-solid border-blue-1000"
          : "border border-dashed"
      }  divide-y divide-gray-200`}
    >
      <div className="flex-1 flex flex-col p-8 w-full">
        <div className="w-32 h-16 flex-shrink-0 mx-auto rounded-full text-5xl font-extrabold text-blue-1000 mb-10">
          <p className="mt-6 text-blue-1000 text-5xl font-extrabold">
            {transactions.length}
          </p>
          <span
            className={`inline-flex items-center px-3 py-0.5 rounded-full text-center justify-center
                                 text-sm font-medium ${
                                   business.isApproved
                                     ? "bg-green-100 text-green-800"
                                     : "bg-yellow-100 text-yellow-800"
                                 } w-24`}
          >
            {business.isApproved ? "Approved" : "In Review"}
          </span>
        </div>
        <h3 className="mt-6 text-gray-900 text-xl font-medium">
          {business.name}
        </h3>
        <p className="mt-2 text-yellow-500 text-xs font-bold italic">
          {business.isApproved
            ? ""
            : "Business in review process. Contact us at support@jlrecharge.com with any question."}
        </p>

        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-gray-500 text-xl">{user?.data.displayName}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="px-2 py-1 text-xs font-medium bg-purple-100 text-purple-800 rounded-full">
              {roleName}
            </span>
          </dd>

          <Link
            to={`${BUSINESS_DETAILS}/${business.uid}`}
            className="inline-flex self-center px-4 py-2 border border-transparent rounded-full shadow-sm text-white
								 bg-blue-1000 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
								 focus:ring-indigo-500 mt-5"
          >
            <BriefcaseIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            View More
          </Link>
        </dl>
      </div>
    </button>
  );
};

BusinessItem.propTypes = {
  id: PropTypes.string.isRequired,
};
export default BusinessItem;
