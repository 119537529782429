import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  AddMoney,
  Business,
  BusinessAdd,
  BusinessAddAgent,
  BusinessDetails,
  Dashboard,
  EmailVerification,
  Payments,
  Profile,
  Recharge,
  Splash,
  TransactionsDetails,
  Transitions,
  WalletAddPaymentMethod,
  WalletPaymentMethodDelete,
  Wallets,
} from "../pages/Index";

import {
  DASHBOARD,
  RECHARGE,
  TRANSACTIONS,
  PROFILE,
  BUSINESS,
  WALLET,
  WALLET_ADD_PAYMENTMETHOD,
  EMAIL_VERIFICATION,
  WALLET_RECHARGE,
  BUSINESS_DETAILS,
  SPLASH,
  BUSINESS_ADD,
  TRANSACTION_DETAILS,
  PAYMENTS,
} from "./routeNames";

const AuthenticatedRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Dashboard pageTitle="Dashboard" />}
        key="Dashboard"
      />
      <Route
        path={SPLASH}
        element={<Splash pageTitle="Splash" />}
        key="Splash"
      />
      <Route
        path={DASHBOARD}
        element={<Dashboard pageTitle="Dashboard" />}
        key="Dashboard"
      />
      <Route
        path={RECHARGE}
        element={<Recharge pageTitle="Recharge" />}
        key="Recharge"
      />
      <Route
        path={TRANSACTIONS}
        element={<Transitions pageTitle="Transactions" />}
        key="Transactions"
      />
      <Route
        path={PAYMENTS}
        element={<Payments pageTitle="Payments" />}
        key="Payments"
      />
      <Route
        path={`${TRANSACTION_DETAILS}/:transUid`}
        element={<TransactionsDetails pageTitle="Transaction Details" />}
        key="Transactions"
      />
      <Route path={PROFILE} element={<Profile />} key="Profile" />
      <Route
        path={BUSINESS}
        element={<Business pageTitle="Business" />}
        key="Business"
      />
      <Route
        path={`${BUSINESS_DETAILS}/:businessId`}
        element={<BusinessDetails pageTitle="Business Details" />}
        key="Business Details"
      />
      <Route
        path={BUSINESS_ADD}
        element={<BusinessAdd pageTitle="Add Business" />}
        key="AddBusiness"
      />
      <Route
        path="/business/:businessId/agent"
        element={<BusinessAddAgent pageTitle="Add Agent" />}
        key="Add Agent"
      />
      <Route
        path={WALLET}
        element={<Wallets pageTitle="Wallets" />}
        key="Wallets"
      />
      <Route
        path={WALLET_ADD_PAYMENTMETHOD}
        element={<WalletAddPaymentMethod pageTitle="Add Payment Method" />}
        key="Add Payment Method"
      />
      <Route
        path="/wallet/payment-method/:paymentMethodId/delete"
        element={
          <WalletPaymentMethodDelete pageTitle="Payment Method Delete" />
        }
        key="Payment Method Delete"
      />
      <Route
        path={WALLET_RECHARGE}
        element={<AddMoney pageTitle="Wallets" />}
        key="Wallets"
      />
      <Route
        path={EMAIL_VERIFICATION}
        element={<EmailVerification />}
        key="EmailVerification"
      />
    </Routes>
  );
};

export default AuthenticatedRouter;
