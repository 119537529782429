import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FirestoreUser from "../../api/firebase/firestore.user";
import {
  USER_READY,
  USER_UNKNOWN,
  USER_UPDATE_FAILED,
  USER_UPDATE_PENDING,
  USER_UPDATE_SUCCESS,
} from "./userStatus";

const initialState = {
  status: USER_UNKNOWN,
};

export const setRole = createAsyncThunk("user/setRole", async (userData) => {
  const user = await FirestoreUser.SetRole(userData);
  return user;
});

export const userUpdate = createAsyncThunk("user/update", async (userData) => {
  const user = await FirestoreUser.Update(userData);
  return user;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userAdd(state, action) {
      state.status = USER_READY;
      state.data = action.payload;
    },
    userStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRole.pending, (state, action) => {
        state.status = USER_UNKNOWN;
        state.data = action.payload;
      })
      .addCase(setRole.rejected, (state, action) => {
        state.status = USER_UNKNOWN;
        state.data = action.payload;
      })
      .addCase(setRole.fulfilled, (state, action) => {
        state.status = action.payload !== null ? USER_READY : USER_UNKNOWN;
        state.data = action.payload;
      })
      .addCase(userUpdate.pending, (state, action) => {
        state.status = USER_UPDATE_PENDING;
        state.data = action.payload;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.status = USER_UPDATE_FAILED;
        state.data = action.payload;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.status =
          action.payload !== null ? USER_UPDATE_SUCCESS : USER_UPDATE_FAILED;
        state.data = action.payload;
      });
  },
});

export const { userAdd, userStatus } = userSlice.actions;

export default userSlice.reducer;
