/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  DASHBOARD,
  RECHARGE,
  TRANSACTIONS,
  PROFILE,
  BUSINESS,
  WALLET,
  PAYMENTS,
} from "../../router/routeNames";

import {
  MenuIcon,
  XIcon,
  CreditCardIcon,
  ChartSquareBarIcon,
  CollectionIcon,
  BriefcaseIcon,
  CashIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { ROLES } from "../../features/authentication/models";
import { useSelector } from "react-redux";
import Logo from "../svg/Logo";
import { isEmpty } from "@firebase/util";

const navigation = [
  {
    name: "Dashboard",
    href: DASHBOARD,
    icon: ChartSquareBarIcon,
    role: ROLES.partner,
  },
  { name: "Recharge", href: RECHARGE, icon: CreditCardIcon, role: ROLES.agent },
  {
    name: "Transactions",
    href: TRANSACTIONS,
    icon: CollectionIcon,
    role: ROLES.agent,
  },
  {
    name: "Business",
    href: BUSINESS,
    icon: BriefcaseIcon,
    role: ROLES.partner,
  },
  { name: "Wallets", href: WALLET, icon: CashIcon, role: ROLES.partner },
  {
    name: "Payments",
    href: PAYMENTS,
    icon: CurrencyDollarIcon,
    role: ROLES.partner,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isNavMenuItemActive = (name, pathname) => {
  if (isEmpty(name)) return false;

  if (isEmpty(pathname)) return false;

  return pathname.indexOf(name.toLowerCase()) >= 0;
};

const withSidebar = (Component) => (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const [navigationByRoles, setNaviagtionRoles] = useState([]);

  const location = useLocation();

  // eslint-disable-next-line react/prop-types
  const { pageTitle } = props;

  useEffect(() => {
    if (user === undefined || user.data === undefined) return;
    const navRoles = navigation.filter((nav) => {
      if (user?.data?.roles === undefined) {
        return;
      }

      // Manager / Partner - Full Access
      if (user.data?.roles[ROLES.manager] || user.data?.roles[ROLES.partner]) {
        return nav;
      }

      // Employ / Agent  - only has acess to Recharge and Transactions
      if (nav.role === ROLES.employee || nav.role === ROLES.agent) {
        return nav;
      }
      return null;
    });
    setNaviagtionRoles(navRoles);
  }, [user]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <Logo />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {navigationByRoles.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.name === pageTitle
                              ? "bg-gray-100 text-gray-900 shadow"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isNavMenuItemActive(item.name, location.pathname)
                                ? "text-white"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                    <Link to={PROFILE} className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {user?.data?.displayName}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Logo />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {navigationByRoles.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      isNavMenuItemActive(item.name, location.pathname)
                        ? "bg-blue-1000 text-white  shadow-md rounded-xl"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center py-4 px-5 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        isNavMenuItemActive(item.name, location.pathname)
                          ? "text-white"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <Link to={PROFILE} className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {user?.data?.displayName}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-25">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 min-h-screen">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="py-4">
                  <div className="rounded-lg">
                    <Component />
                  </div>
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default withSidebar;
