import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { compose } from "redux";
import withSidebar from "../../components/HOC/withSidebar";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import FirestoreBusiness from "../../api/firebase/firestore.business";
import AgentCollections from "../../features/business/AgentCollections";
import BusinessInsightCardCollections from "../../features/business/BusinessInsightCardCollections";

const BusinessDetails = () => {
  const params = useParams();
  const [business, setBusiness] = useState({});
  useEffect(() => {
    async function loadBusiness() {
      const bu = await FirestoreBusiness.LoadById(params.businessId);
      if (bu !== null) {
        setBusiness(bu);
      }
    }
    loadBusiness();
  }, [params.businessId]);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {business?.name}
          </h2>
        </div>
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          {/* Start main area*/}
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <AgentCollections business={business} />
            </div>
          </div>
          {/* End main area */}
        </main>
        <aside className="hidden relative xl:flex xl:flex-col flex-shrink-0 w-1/3 border-l border-gray-200 overflow-y-auto">
          {/* Start secondary column (hidden on smaller screens) */}
          <div className="inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="rounded-lg">
              <BusinessInsightCardCollections
                business={business}
                title="My Stats"
              />
            </div>
          </div>
          {/* End secondary column */}
        </aside>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(BusinessDetails);
