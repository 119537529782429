import React from "react";
import PropTypes from "prop-types";

const BusinessInsightCard = ({ title, data, simbol }) => {
  return (
    <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">
        {simbol} {Number(data).toFixed(2)}
      </dd>
    </div>
  );
};

BusinessInsightCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  simbol: PropTypes.string.isRequired,
};
export default BusinessInsightCard;
