import React from "react";
import AuthEmailVerified from "../../features/authentication/AuthEmailVerified";

const EmailVerified = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="w-2/3">
        <AuthEmailVerified />
      </div>
    </div>
  );
};

export default EmailVerified;
