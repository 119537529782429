import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import withSidebar from "../../components/HOC/withSidebar";
import AddDebitCard from "../../features/wallets/AddDebitCard";
import WalletProcessing from "../../features/wallets/WalletProcessing";
import { WALLETS_READY } from "../../features/wallets/walletStatus";
import { WALLET } from "../../router/routeNames";
const WalletAddPaymentMethod = () => {
  const wallet = useSelector((state) => state.wallet);

  if (wallet.status !== WALLETS_READY) {
    return <WalletProcessing />;
  }

  return (
    <div className="font-Poppins">
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Payment Method
          </h2>
        </div>
        <Link
          to={WALLET}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                        text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Back
        </Link>
      </div>
      <div className="flex-1 flex-col xl:flex-row relative z-0 flex overflow-hidden rounded-3xl bg-white">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6">
            <div className="rounded-lg p-10">
              <AddDebitCard />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(WalletAddPaymentMethod);
