import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import withSidebar from "../../components/HOC/withSidebar";
import { authenticationSignOut } from "../../features/authentication/authenticationSlice";
import UserAddress from "../../features/user/UserAddress";
import UserProfile from "../../features/user/UserProfile";
import UserState from "../../features/user/UserState";
import { USER_READY } from "../../features/user/userStatus";
import { LOGIN } from "../../router/routeNames";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const handleLogOut = async () => {
    await FirebaseAuth.SignOut();
    dispatch(authenticationSignOut(null));
    navigate(LOGIN, { replace: true });
  };

  if (user.status !== USER_READY) {
    return <UserState />;
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Profile Information
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            onClick={handleLogOut}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-white bg-blue-1000 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            LogOut
          </button>
        </div>
      </div>

      <UserProfile />
      <UserAddress />
    </div>
  );
};

export default withSidebar(Profile);
