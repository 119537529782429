import React from "react";
import { useSelector } from "react-redux";
import DateRangeFilter from "../filters/DateRangeFilter";
import {
  PAYMENTS_LOADED_FAILED,
  PAYMENTS_LOADED_PENDING,
} from "./paymentState";

const PaymentCollection = () => {
  const paymentStaus = useSelector((state) => state.payments);
  const paymentsEntities = useSelector((state) => state.payments.entities);
  const payments = Object.values(paymentsEntities);

  if (paymentStaus === PAYMENTS_LOADED_FAILED) {
    return <div>Payment Unavailable</div>;
  }

  if (paymentStaus === PAYMENTS_LOADED_PENDING) {
    return <div>Payment Loading</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Payments</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <DateRangeFilter />
        </div>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden  ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-50">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Amount
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Description
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Brand
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                {" "}
                Card Last 4
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50 bg-white">
            {payments.map((payment) => (
              <tr key={payment.uid}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {new Date(
                    (payment.created.seconds +
                      payment.created.nanoseconds * 0.00000001) *
                      1000
                  ).toLocaleString()}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  ${Number(payment.amount).toFixed(2)}{" "}
                  {payment?.currency?.toUpperCase()}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {payment.description}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {payment.brand}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  ...{payment.last4}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentCollection;
