import React from "react";
import ReactDOM from "react-dom/client";
import "./main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Elements stripe={promise}>
        <App />
      </Elements>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
