export const TRANSACTIONS_UNKNOWN = "transactions_unknown";

// basic transaction created once user launched in Recharge page.
export const TRANSACTION_DRAFT = "transaction_draft";

// Loading all transactions
export const TRANSACTIONS_LOADING = "transactions_loading";

// Loading transaction with sucess
export const TRANSACTIONS_LOAD_SUCESS = "transactions_load_success";

// Loading transaction failed.
export const TRANSACTIONS_LOAD_FAILED = "transactions_load_failed";

// Services Processing Transactions
export const TRANSACTION_PROCESSING = "transaction_processing";

//Services Processing Transactions -  Failed
export const TRANSACTION_PROCESSING_FAILED = "transaction_processing_failed";

//Services Processing Transactions
export const TRANSACTION_PROCESSING_COMPLETE =
  "transaction_processing_complete";
