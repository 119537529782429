import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PlusSmIcon as PlusSmIconOutline } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { BUSINESS_ADD_AGENT } from "../../router/routeNames";
import Agent from "./Agent";
import FirestoreUser from "../../api/firebase/firestore.user";
import AgentState from "./AgentState";

const AgentCollections = ({ business }) => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    async function loadAgents() {
      if (business === undefined || business.uid === undefined) return;

      const userAgents = await FirestoreUser.LoadByBusinessId(business.uid);
      if (userAgents === null || userAgents.length === 0) return;

      setAgents(userAgents);
    }

    loadAgents();
  }, [business.uid]);

  const renderAgents = () => {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {agents.map((agent) => (
          <Agent key={agent.email} agent={agent} />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between mb-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{`${business.name} authorized Agents`}</h3>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <Link
            to={BUSINESS_ADD_AGENT(business.uid)}
            className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm
								 text-white bg-indigo-600 hover:bg-indigo-700 text-sm
									focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Agent
            <PlusSmIconOutline
              className="h-5 w-5 ml-2 bg-white text-blue-1000 rounded-full"
              aria-hidden="true"
            />
          </Link>
        </div>
      </div>

      {agents && agents.length === 0 ? (
        <AgentState
          title="No Agents"
          description="Add Authorized agent to your Business"
        />
      ) : (
        renderAgents()
      )}
    </>
  );
};

AgentCollections.propTypes = {
  business: PropTypes.object.isRequired,
};
export default AgentCollections;
