import React from "react";

import { useSelector } from "react-redux";
import { getRole } from "../authentication/models";

const UserProfile = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
      <div className="flex flex-grow justify-between">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Personal Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details information.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <span
            className={`inline-flex items-center px-5 py-2 rounded text-xs font-bold uppercase ${
              user?.data?.isActive ? "bg-green-500" : "bg-yellow-400"
            } text-white`}
          >
            {user?.data?.isActive ? "Approved" : "In Review"}
          </span>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Full name</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user?.data?.name || "No name"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              JLRecharge Role
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {getRole(user?.data?.roles)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user?.data?.email || "no email"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user?.data?.phone || "no phone"}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default UserProfile;
