import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  rechargeMessageAdd,
  rechargePaymentMethodIdAdd,
  rechargeStatus,
} from "../recharge/rechargeSlice";
import {
  RECHARGE_PROCESS_PAYMENT,
  RECHARGE_PROCESS_PAYMENT_FAILED,
  RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY,
  RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT,
  RECHARGE_PROCESS_TOPUP,
  RECHARGE_PROCESS_TOPUP_FAILED,
  RECHARGE_PROCESS_TOPUP_SUCCESSFULLY,
} from "../recharge/rechargeStatus";
import { ERROR } from "../../api/http";
import { selectOperatorById } from "../operators/operatorsSlice";
import { codeGenerator } from "../../utils/codeGenerator";
import { ProviderClient } from "../../api/providers/client";
import { selectPaymentMethodByPaymentMethodId } from "../paymentMethods/paymentMethodsSlice";
import { isEmpty } from "../../utils/stringHelper";
import { walletAdd, walletUpdate } from "./walletSlice";
import { transactionsAdd } from "../transactions/transactionsSlice";
import { concactCallingCodeAndPhone } from "../../utils/phoneHelper";
import { TRANSACTION_PROCESSING_COMPLETE } from "../transactions/transactionState";
import { selectBusinessSelected } from "../business/businessSlice";
import NoPaymentMethod from "../paymentMethods/NoPaymentMethod";
import { logsAdd } from "../logs/logSlice";
import Log from "../../models/log";
import FirestoreWallets from "../../api/firebase/firestore.wallets";
import Loader from "../../components/loader/Loader";

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const recharge = useSelector((state) => state.recharge);
  const operator = useSelector((state) =>
    selectOperatorById(state, recharge.operatorId)
  );
  const wallet = useSelector((state) => state.wallet);
  const paymentMethod = useSelector((state) =>
    selectPaymentMethodByPaymentMethodId(state, wallet?.data?.paymentMethodId)
  );
  const businessSelected = useSelector((state) =>
    selectBusinessSelected(state)
  );
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  useEffect(() => {
    if (wallet !== undefined) {
      dispatch(rechargePaymentMethodIdAdd(wallet?.data?.paymentMethodId));
    }
  }, []);

  const handlePaymentClick = async () => {
    try {
      if (!recharge.phone) {
        setInputErrorMessage("Please enter the valid phone number.");
        return;
      }
      if (!recharge.amountReceived || Number(recharge.amountReceived) == 0) {
        setInputErrorMessage(
          "The amount must be greater than 0. Please enter a valid amount."
        );
        return;
      }
      setInputErrorMessage("");
      setIsLoading(true);
      const phoneNumber = concactCallingCodeAndPhone(
        recharge.countryCallingCode,
        recharge.phone
      );
      //TODO: Check if Wallet has enough Money
      if (Number(wallet?.data?.amount) < Number(recharge.totalAmount)) {
        dispatch(rechargeStatus(RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT));
        return;
      }
      let walletAmount =
        Number(wallet?.data?.amount) -
        Number(recharge.totalAmount) +
        Number(recharge.userCommission);
      walletAmount = walletAmount.toFixed(2);
      const walletData = {
        ...wallet.data,
        amount: Number(walletAmount),
      };

      dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT));

      const updateWallet = await FirestoreWallets.Update(walletData);
      if (walletUpdate == null) {
        dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT_FAILED));
        return;
      }

      dispatch(walletAdd(updateWallet));
      dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY));
      const topUpReference = codeGenerator();
      const providerClient = new ProviderClient(
        process.env.REACT_APP_PROVIDER_URL
      );
      let topUpData = {
        reference: topUpReference,
        operatorId: recharge.operatorId,
        amount: Number(recharge.amountSent),
        amountReceived: operator.supportLocalAmount
          ? Number(recharge.amountReceived)
          : Number(recharge.amountSent),
        useLocalAmount: operator.supportLocalAmount,
        ValidateOnly: false,
        customerIdentifier: topUpReference,
        skuCode: operator.skuCode,
        currency: user?.data.currencyCode?.toUpperCase(),
        recipientPhone: {
          countryCode: recharge.receiverCountryCode,
          number: phoneNumber,
        },
        senderPhone: {
          countryCode: user?.data.countryCode,
          number: user?.data.phoneNumber || "1401-216-7175",
        },
        date: new Date().toISOString(),
      };

      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP));
      topUpData.providerId = operator.providerId;
      topUpData.commission = operator.commissionGain;

      let topUpResult = await providerClient.Reacharge(
        `/${operator.endPoint}`,
        JSON.stringify(topUpData)
      );
      if (topUpResult.status === ERROR || topUpResult.data?.status === ERROR) {
        // Tracked Failed
        let logData = {};
        logData.userId = user?.data?.uid;
        logData.contact = user?.data?.email || user?.data?.phoneNumber;
        logData.phone = phoneNumber;
        logData.providerId = topUpData.providerId;
        logData.providerName = operator.endPoints[0];
        logData.amount = topUpData.amount;
        logData.message = topUpResult?.data?.error || "";
        logData.type = RECHARGE_PROCESS_TOPUP_FAILED;

        dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_FAILED));
        dispatch(rechargeMessageAdd(topUpResult?.data || "Failed Second Try"));
        dispatch(logsAdd(new Log(logData)));

        walletAmount =
          Number(walletAmount) +
          Number(recharge.totalAmount) -
          Number(recharge.userCommission);
        walletAmount = walletAmount.toFixed(2);
        const walletData = {
          ...wallet.data,
          amount: Number(walletAmount),
        };

        const updateWallet = await FirestoreWallets.Update(walletData);
        if (walletUpdate == null) {
          return;
        }
        dispatch(walletAdd(updateWallet));
        return;
      }

      const transaction = {
        reference: topUpReference,
        operatorId: recharge.operatorId,
        operatorFlag: operator.logo,
        amountReceived: recharge.amountReceived,
        amountSent: Number(recharge.amountSent),
        fees: Number(recharge.fees),
        totalAmount: Number(recharge.totalAmount),
        phone: phoneNumber,
        userId: user?.data?.uid,
        senderCountryCode: user?.data?.countryCode,
        receiverCountryCode: recharge.receiverCountryCode,
        operatorName: recharge.operatorName,
        rates: recharge.rates,
        paymentMethodId: recharge.paymentMethodId,
        senderCurrencyCode: user?.data?.currencyCode,
        receiverCurrencyCode: recharge.receiverCurrencyCode,
        commission: topUpData.commission,
        providerId: topUpData.providerId,
        skuCode: operator.skuCode,
        device: "jlrspace",
        status: TRANSACTION_PROCESSING_COMPLETE,
        businessId: businessSelected.uid,
        userCommission: Number(recharge.userCommission),
      };

      dispatch(transactionsAdd(transaction));
      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_SUCCESSFULLY));
    } catch (error) {
      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_FAILED));
      dispatch(rechargeMessageAdd(error.message));
      const logData = {};
      logData.userId = user?.data?.uid;
      logData.phone = recharge.phone;
      // TODO: Find the provider Name used
      logData.providerName = "FindOut";
      logData.message = error.message || error;
      logData.type = "EXCEPTION";
      dispatch(logsAdd(new Log(logData)));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (Number(recharge.amountReceived > 0) || recharge.phone) {
      setInputErrorMessage("");
    }
  }, [recharge.amountReceived, recharge.phone]);
  const renderPaymentMethods = () => {
    return (
      <div className="px-8 pb-5">
        {isLoading ? (
          <Loader />
        ) : (
          <button
            onClick={handlePaymentClick}
            type="button"
            disabled={!isEmpty(wallet?.data?.paymentMethodId) ? false : true}
            className={`rounded-md w-full justify-center inline-flex items-center px-6 mt-2 py-3 border border-transparent 
							text-base font-medium shadow-sm ${
                !isEmpty(wallet?.data?.paymentMethodId)
                  ? "text-white bg-indigo-600"
                  : "bg-gray-400 text-gray-900"
              } hover:bg-indigo-700 focus:outline-none 
							focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Send -{" "}
            {`$${recharge.amountReceived || "0.00"} ${
              recharge.receiverCurrencyCode || ""
            }`}
          </button>
        )}
        {inputErrorMessage ? (
          <p className="text-xs font-bold text-red-600 mt-2">
            {inputErrorMessage}
          </p>
        ) : null}
      </div>
    );
  };

  return paymentMethod !== undefined ? (
    renderPaymentMethods()
  ) : (
    <NoPaymentMethod
      title="Payment Methods"
      description="Add Payment Method to Recharge your Wallet."
    />
  );
};

export default PaymentMethods;
