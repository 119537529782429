import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CreditCardIcon } from "@heroicons/react/outline";
import { WALLET_ADD_PAYMENTMETHOD } from "../../router/routeNames";

const NoPaymentMethod = ({ title, description }) => {
  return (
    <div className="flex  flex-col justify-center border-2 border-gray-300 border-dashed rounded-lg p-12  hover:border-gray-40">
      <div className="flex justify-center mb-5">
        <CreditCardIcon
          className="h-20 w-20 text-blue-1000"
          aria-hidden="true"
        />
      </div>
      <div className="flex justify-center mb-5">
        <span className="text-gray-500 font-semibold">{description}</span>
      </div>
      <div className="flex justify-center mb-5">
        <Link
          to={WALLET_ADD_PAYMENTMETHOD}
          className="py-3 px-6 text-lg font-bold bg-blue-1000 rounded-2xl text-white border-dotted bottom-2 border-blue-1000"
        >
          {title}
        </Link>
      </div>
    </div>
  );
};

NoPaymentMethod.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default NoPaymentMethod;
