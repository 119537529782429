import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import { TRANSACTION_DETAILS } from "../../router/routeNames";
import { ROLES } from "../authentication/models";
import { selectBusinessSelected } from "../business/businessSlice";
import DateRangeFilter from "../filters/DateRangeFilter";
import PhoneNumberFilter from "../filters/PhoneNumberFilter";
import CtaDownload from "../reports/CtaDownload";
import { transactionsSetAll } from "./transactionsSlice";

const TransactionCollection = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const business = useSelector((state) => selectBusinessSelected(state));
  const filters = useSelector((state) => state.filters);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    async function refreshTransactionsByDate() {
      if (user === undefined || user?.data === undefined) return;
      if (business === undefined) return;
      const refreshTransactions = user?.data.roles[ROLES.agent]
        ? await FirestoreTransactions.LoadByFilter(filters, user?.data?.uid)
        : await FirestoreTransactions.LoadByFilterAndBusinessId(
            filters,
            business.uid
          );
      if (refreshTransactions !== null && refreshTransactions.length >= 0) {
        setTransactions(refreshTransactions);
        dispatch(transactionsSetAll(refreshTransactions));
      }
    }
    refreshTransactionsByDate();
  }, [business?.uid, filters]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Transactions</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <PhoneNumberFilter />
          <DateRangeFilter />
          <CtaDownload />
        </div>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden  ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-50">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Date
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Reference
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Operator
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                {" "}
                Phone
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Selling Price
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Cost Price
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Commission
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-50 bg-white">
            {transactions.map((transaction) => (
              <tr key={transaction.uid}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {new Date(
                    (transaction.date.seconds +
                      transaction.date.nanoseconds * 0.00000001) *
                      1000
                  ).toLocaleString()}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <Link
                    className="text-blue-1000 hover:underline"
                    to={`${TRANSACTION_DETAILS}/${transaction.uid}`}
                  >
                    {transaction.reference}
                  </Link>
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {transaction.operatorName}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {transaction.phone}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {transaction.senderCurrencyCode}{" "}
                  {Number(transaction.amountSent).toFixed(2)}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {transaction.senderCurrencyCode}{" "}
                  {Number(transaction.amountSent) -
                    Number(transaction.userCommission)}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {transaction.senderCurrencyCode}{" "}
                  {Number(transaction.userCommission).toFixed(2)}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <Link
                    className="text-blue-1000 hover:underline"
                    to={`${TRANSACTION_DETAILS}/${transaction.uid}`}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionCollection;
