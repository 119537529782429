import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  rechargeOperatorIdAdd,
  rechargeOperatorNameAdd,
} from "../recharge/rechargeSlice";
import { operatorsSelected, selectOperatorById } from "./operatorsSlice";

const Operator = ({ id }) => {
  const dispatch = useDispatch();
  const operator = useSelector((state) => selectOperatorById(state, id));
  const recharge = useSelector((state) => state.recharge);

  const handleSelectOperator = () => {
    dispatch(rechargeOperatorIdAdd(id));
    dispatch(rechargeOperatorNameAdd(operator?.name));
    dispatch(operatorsSelected(id));
  };

  return (
    <li
      key={operator?.name}
      className={` ${
        operator?.selected || recharge.operatorId === id
          ? "border-2 border-purple-400"
          : "border border-gray-25"
      } 
			cursor-pointer flex flex-row shadow-md rounded-md px-2 mb-2 bg-white`}
      onClick={handleSelectOperator}
    >
      <div className="h-14 w-14 bg-white">
        <img
          className="object-cover w-full h-full p-2"
          src={operator?.logo}
          alt=""
        />
      </div>
      <div className="bg-white rounded-r-md truncate h-14 flex flex-1">
        <div className="py-5 text-sm truncate">
          <div className="text-gray-700 font-semibold text-sm md:text-base">
            {operator?.name}
          </div>
        </div>
      </div>
    </li>
  );
};

Operator.propTypes = {
  id: PropTypes.number.isRequired,
};
export default Operator;
