import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LibraryIcon } from "@heroicons/react/outline";
import { BUSINESS } from "../../router/routeNames";
import PropTypes from "prop-types";
import {
  BUSINESS_ADD_AGENT_FAILED,
  BUSINESS_ADD_AGENT_PENDING,
  BUSINESS_ADD_AGENT_SUCCESS,
  BUSINESS_ADD_FAILED,
  BUSINESS_ADD_PENDING,
  BUSINESS_ADD_SUCCESS,
  BUSINESS_READY,
  BUSINESS_UNKNOWN,
  BUSINESS_UPDATE_FAILED,
  BUSINESS_UPDATE_PENDING,
} from "./businessStatus";
import { businessStatus } from "./businessSlice";

const BusinessState = ({ route }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const business = useSelector((state) => state.businesses);

  useEffect(() => {
    if (business.status === BUSINESS_ADD_FAILED) {
      setTitle("Add Business");
      setDescription("Failed Add Business");
    }

    if (business.status === BUSINESS_ADD_PENDING) {
      setTitle("Add Business");
      setDescription("Adding your Business to JLRbusiness network.");
    }

    if (business.status === BUSINESS_ADD_SUCCESS) {
      setTitle("Done");
      setDescription("Your Business was created with Success.");
    }

    if (business.status === BUSINESS_UPDATE_FAILED) {
      setTitle("Update Business");
      setDescription("Failed updating Business");
    }

    if (business.status === BUSINESS_UPDATE_PENDING) {
      setTitle("Update Business");
      setDescription("Updating your Business in our JLRSpace network.");
    }

    if (business.status === BUSINESS_UNKNOWN) {
      setTitle("Loading...");
      setDescription("Loading your business data.");
    }

    if (business.status === BUSINESS_READY && business.ids.length === 0) {
      setTitle("Add Business");
      setDescription("Add your business to JLRSpace network.");
    }

    if (business.status === BUSINESS_ADD_AGENT_PENDING) {
      setTitle("Processing");
      setDescription("Adding agent to your business");
    }

    if (business.status === BUSINESS_ADD_AGENT_FAILED) {
      setTitle("Failed");
      setDescription(
        "Failed adding agent. Contact us - support@jlrecharge.com"
      );
    }

    if (business.status === BUSINESS_ADD_AGENT_SUCCESS) {
      setTitle("Success!");
      setDescription("Your agent was created successefuly.");
    }
  }, [business.status]);

  const handleAction = () => {
    dispatch(businessStatus(BUSINESS_READY));
    navigate(route);
  };

  return (
    <div className="flex  flex-col justify-center border-2 border-gray-300 border-dashed rounded-lg p-12  hover:border-gray-40">
      <div className="flex justify-center mb-5">
        <LibraryIcon className="h-20 w-20 text-blue-1000" aria-hidden="true" />
      </div>
      <div className="flex justify-center mb-5">
        <span className="text-gray-500 font-semibold">{description}</span>
      </div>
      <div className="flex justify-center mb-5">
        <button
          onClick={handleAction}
          className="py-3 px-6 text-lg font-bold bg-blue-1000 rounded-2xl text-white border-dotted bottom-2 border-blue-1000"
        >
          {title}
        </button>
      </div>
    </div>
  );
};

BusinessState.defaultProps = {
  route: BUSINESS,
};

BusinessState.propTypes = {
  route: PropTypes.string,
};
export default BusinessState;
