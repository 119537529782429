import React from "react";
import PropTypes from "prop-types";
import { XCircleIcon } from "@heroicons/react/solid";
import { isEmpty } from "../../utils/stringHelper";

const alertType = (type) => {
  switch (type.toLowerCase()) {
    case "success":
      return "text-green-800";
    case "error":
      return "text-red-800";
    case "warning":
      return "text-yellow-800";
    default:
      return "";
  }
};

const Alert = ({ title, message, type }) => {
  const renderAlert = () => {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon
              className={`h-5 w-5 text-red-400 ${alertType(type)}`}
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium  ${alertType(type)}`}>
              {title}
            </h3>
            <div className={`mt-2 text-sm  ${alertType(type)}`}>
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return !isEmpty(title) && !isEmpty(message) ? renderAlert() : "";
};

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
};

export default Alert;
