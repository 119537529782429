/**
 * Validate if the argument (email) is a valid email.
 *
 * Args:
 *      email (String): The email address to be validated using regex.
 *
 * Returns:
 *      (Bool): return True if the email is valid. False otherwise.
 *
 */
export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

/**
 * Validate if the argument (password) is a valid password.
 *
 * Args:
 *      password (String): The password to be validated using regex.
 *
 * Returns:
 *      (Bool): return True if the password is valid. False otherwise.
 *
 */

export const isValidPassword = (password) => {
  var re = {
    lowercase: /(?=.*[a-z])/,
    capital: /(?=.*[A-Z])/, // At least one Upercase
    digit: /(?=.*[0-9])/, // At least one digit
    specialCharacter: /(?=.*[!@#$%^&?*])/,
    Length: /(?=.{8,})/,
  };

  return (
    re.capital.test(password) &&
    re.digit.test(password) &&
    re.lowercase.test(password) &&
    re.specialCharacter.test(password) &&
    re.Length.test(password)
  );
};
