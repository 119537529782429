import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WALLET_RECHARGE } from "../../router/routeNames";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
const AutoRechargeConfirmation = () => {
  const wallet = useSelector((state) => state.wallet);
  const navigate = useNavigate();

  const handleRecharge = () => {
    navigate(WALLET_RECHARGE);
  };
  if (
    !wallet ||
    wallet?.data?.amount >= wallet?.data?.minBalance ||
    !wallet?.data?.autoRecharge
  ) {
    return;
  }

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              There are problems with the Auto Recharge. Please add the recharge
              manually.
            </p>
          </div>
        </div>
      </div>
      <div className="flex mx-7 mt-4">
        <button
          type="button"
          className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-3"
          onClick={handleRecharge}
        >
          Recharge
        </button>
      </div>
    </div>
  );
};

export default AutoRechargeConfirmation;
