import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { RATES_UNKNOWN } from "./ratesStatus";

const ratesAdapter = createEntityAdapter({
  selectId: (rate) => rate.base,
});

const initialState = ratesAdapter.getInitialState({
  status: RATES_UNKNOWN,
});

const ratesSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {
    ratesStatus(state, action) {
      state.status = action.payload;
    },
    ratesAddMany: ratesAdapter.addMany,
    ratedAddOne: ratesAdapter.addOne,
  },
});

export default ratesSlice.reducer;

export const { ratesStatus, ratesAddMany, ratedAddOne } = ratesSlice.actions;

export const { selectAll: selectRates, selectById: selectRateById } =
  ratesAdapter.getSelectors((state) => state.rates);

export const selectSettingsIds = createSelector(selectRates, (rates) =>
  rates.map((rate) => rate.uid)
);

export const selectRatesBase = createSelector(
  // All Rates
  selectRates,

  // Pass rate base Argument
  (_, base) => base,

  (rates, base) => rates.filter((rate) => rate.base === base)[0]
);
