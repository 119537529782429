import React from "react";
import SignIn from "../../features/authentication/SignIn";

const Login = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="w-2/3">
        <SignIn />
      </div>
    </div>
  );
};

export default Login;
