import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { isEmpty } from "../../utils/stringHelper";

import firebaseInit from "./firebase.index";

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreTransactions {
  static async LoadById(transId) {
    try {
      const userDocRef = doc(db, "transactions", transId);

      const userDocSnap = await getDoc(userDocRef);

      return userDocSnap.exists() ? userDocSnap.data() : null;
    } catch (error) {
      return null;
    }
  }

  static async LoadByUserId(userId) {
    try {
      const transactionCollection = [];
      const queryTransactions = query(
        collection(db, "transactions"),
        where("userId", "==", userId)
      );

      const transactionsSnapShot = await getDocs(queryTransactions);

      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadByBusinessId(businessId) {
    try {
      const transactionCollection = [];
      const queryTransactions = query(
        collection(db, "transactions"),
        where("businessId", "==", businessId)
      );

      const transactionsSnapShot = await getDocs(queryTransactions);

      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadByUserIdAndDate(userId, date) {
    try {
      const transactionCollection = [];

      const transactionRef = collection(db, "transactions");

      const dtMinesUm = new Date(new Date(date).setHours(-24));
      const dt = new Date(new Date().setHours(24));

      const queryTransactionsByUserIdAndDate = query(
        transactionRef,
        where("userId", "==", userId),
        where("date", ">=", dtMinesUm),
        where("date", "<=", dt)
      );
      const transactionsSnapShot = await getDocs(
        queryTransactionsByUserIdAndDate
      );

      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadByUserandStatus(userId, status) {
    try {
      const transactionCollection = [];

      const transactionRef = collection(db, "transactions");
      const queryTransactionsByUserIdAndStatus = query(
        transactionRef,
        where("userId", "==", userId),
        where("status", "==", status)
      );
      const transactionsSnapShot = await getDocs(
        queryTransactionsByUserIdAndStatus
      );
      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadByFilter(filter, userId) {
    try {
      const transactionCollection = [];

      const transactionRef = collection(db, "transactions");
      let dtMinesUm = new Date(new Date(filter.date));
      let dt = new Date(new Date().setHours(24));
      if (filter.dateStart !== undefined) {
        dtMinesUm = new Date(new Date(filter.dateStart));
      }

      if (filter.dateEnd !== undefined) {
        dt = new Date(new Date(filter.dateEnd).setHours(24));
      }
      let queryTransactionsByUserIdAndDate = query(
        transactionRef,
        where("userId", "==", userId),
        where("date", ">", dtMinesUm),
        where("date", "<", dt)
      );

      // Apply Operators
      if (filter.operatorId !== undefined && filter.operatorId > 0) {
        queryTransactionsByUserIdAndDate = query(
          transactionRef,
          where("userId", "==", userId),
          where("operatorId", "==", filter.operatorId),
          where("date", ">", dtMinesUm),
          where("date", "<", dt)
        );
      }

      if (!isEmpty(filter.phone)) {
        queryTransactionsByUserIdAndDate = query(
          transactionRef,
          where("userId", "==", userId),
          where("phone", "==", filter.phone)
        );
      }
      const transactionsSnapShot = await getDocs(
        queryTransactionsByUserIdAndDate
      );
      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadByFilterAndBusinessId(filter, businessId) {
    try {
      const transactionCollection = [];

      const transactionRef = collection(db, "transactions");
      let dtMinesUm = new Date(new Date(filter.date));
      let dt = new Date(new Date().setHours(24));
      if (filter.dateStart !== undefined) {
        dtMinesUm = new Date(new Date(filter.dateStart));
      }

      if (filter.dateEnd !== undefined) {
        dt = new Date(new Date(filter.dateEnd).setHours(24));
      }
      let queryTransactionsByUserIdAndDate = query(
        transactionRef,
        where("businessId", "==", businessId),
        where("date", ">", dtMinesUm),
        where("date", "<", dt)
      );

      // Apply Operators
      if (filter.operatorId !== undefined && filter.operatorId > 0) {
        queryTransactionsByUserIdAndDate = query(
          transactionRef,
          where("businessId", "==", businessId),
          where("operatorId", "==", filter.operatorId),
          where("date", ">", dtMinesUm),
          where("date", "<", dt)
        );
      }

      if (!isEmpty(filter.phone)) {
        queryTransactionsByUserIdAndDate = query(
          transactionRef,
          where("businessId", "==", businessId),
          where("phone", "==", filter.phone)
        );
      }

      const transactionsSnapShot = await getDocs(
        queryTransactionsByUserIdAndDate
      );

      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadTodayByBusinessId(businessId) {
    try {
      const transactionCollection = [];

      const transactionRef = collection(db, "transactions");
      const dtMinesUm = new Date(new Date());
      const dt = new Date(new Date().setHours(24));
      let queryTransactionsByBusinessId = query(
        transactionRef,
        where("businessId", "==", businessId),
        where("date", ">", dtMinesUm),
        where("date", "<", dt)
      );

      const transactionsSnapShot = await getDocs(queryTransactionsByBusinessId);
      transactionsSnapShot.forEach((snapshot) => {
        transactionCollection.push(snapshot.data());
      });
      return transactionCollection;
    } catch (error) {
      return null;
    }
  }

  static async Create(transaction) {
    try {
      const newTransaction = transaction;
      const pmRef = doc(collection(db, "transactions"));

      newTransaction.uid = pmRef.id;
      newTransaction.date = serverTimestamp();

      await setDoc(pmRef, { ...newTransaction }, { merge: true });

      return newTransaction;
    } catch (error) {
      return null;
    }
  }
}
