import { ArrowCircleLeftIcon, InboxInIcon } from "@heroicons/react/outline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import { FORGOT_PASSWORD } from "../../router/routeNames";
import { isValidEmail } from "../../utils/regexHelper";
import { authenticationStatusUpdate } from "./authenticationSlice";
import {
  AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT,
  AUTHENTICATION_UNKNOW,
} from "./authState";

const EmailResentPasswordSent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector((state) => state.authentication);

  const sendLinkResetPassword = async () => {
    if (!isValidEmail(authentication.data?.email)) {
      return;
    }

    await FirebaseAuth.SendResetPasswordEmailLink(authentication.data?.email);
    dispatch(
      authenticationStatusUpdate(AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT)
    );
    return;
  };

  const backResetPassword = () => {
    dispatch(authenticationStatusUpdate(AUTHENTICATION_UNKNOW));
    navigate(FORGOT_PASSWORD);
  };

  return (
    <div className="flex justify-center align-middle content-center">
      <div className="py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white rounded-2xl shadow-sm">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-row">
            <button onClick={backResetPassword}>
              <ArrowCircleLeftIcon
                className="h-10 w-10 mr-2 text-blue-1000"
                aria-hidden="true"
              />
            </button>
            <h2 className="mx-6 my-2 text-base font-extrabold text-gray-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-8">
            <div className="flex flex-col">
              <div className="mb-6 flex justify-center">
                <InboxInIcon
                  className="h-10 w-10 mr-2 text-blue-1000"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col justify-center">
                <h2 className="font-semibold mb-6 text-center">
                  Check your email
                </h2>
                <p className="text-gray-500 mb-6 text-center">
                  We have send you a email with a password <br /> reset
                  instructions. Please follow the instructions.
                </p>
              </div>

              <div className="mb-6">
                <a
                  href="mailto:"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Open Email
                </a>
              </div>
              <div>
                <p className="text-gray-500 font-normal">
                  Didn’t received the email? Check spam folder. or{" "}
                  <button
                    onClick={sendLinkResetPassword}
                    className="text-indigo-500 font-medium"
                  >
                    {" "}
                    Resend the email
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailResentPasswordSent;
