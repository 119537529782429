import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { compose } from "redux";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import withSidebar from "../../components/HOC/withSidebar";
import { useParams } from "react-router-dom";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import RechargeDetails from "../../features/recharge/rechargeDetails";
import { isEmpty } from "../../utils/stringHelper";

const TransactionsDetails = () => {
  const params = useParams();
  const [transaction, setTransaction] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    async function loadTransactionDetails() {
      if (isEmpty(params.transUid)) {
        return;
      }

      const trans = await FirestoreTransactions.LoadById(params.transUid);
      if (trans !== null) {
        setTransaction(trans);
      }
    }

    loadTransactionDetails();
  }, [params]);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-10">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Transaction Details : {transaction.reference}
          </h2>
        </div>
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <RechargeDetails user={user} recharge={transaction} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(TransactionsDetails);
