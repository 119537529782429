import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FirestoreTransactions from "../../api/firebase/firestore.transactions";
import { isEmpty } from "../../utils/stringHelper";
import { ROLES } from "../authentication/models";
import { selectBusinessSelected } from "../business/businessSlice";
import { selectRatesBase } from "../rates/ratesSlice";
import { selectSettingsByType } from "../settings/settingsSlice";
import WalletStats from "../wallets/WalletStats";
import { TransactionStatsType } from "./constants";
import { transactionsAddMany } from "./transactionsSlice";

import TransactionStats from "./TransactionStats";

const TransactionsStatsCollection = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const business = useSelector((state) => selectBusinessSelected(state));
  const filters = useSelector((state) => state.filters);
  const setting = useSelector((state) => selectSettingsByType(state, "app"));
  const rateUsa = useSelector((state) =>
    selectRatesBase(state, user?.data?.currencyCode)
  );
  const recharge = useSelector((state) => state.recharge);
  const wallet = useSelector((state) => state.wallet);

  const [totalTransaction, setTotalTransaction] = useState("");
  const [totalCommission, setTotalCommission] = useState("");
  const [totalRechargeSold, setTotalRechargeSold] = useState("");
  const [totalRechargeCost, setTotalRechargeCost] = useState("");

  const computeTotalCommission = (transations) => {
    let totalCommission = 0;
    transations.forEach((transation) => {
      if (!isEmpty(transation.userCommission)) {
        totalCommission += Number(transation.userCommission);
      }
    });
    setTotalCommission(Number(totalCommission).toFixed(2));
  };

  const computeTotalRechargeStats = (transactions) => {
    let totalRechargeSoldValue = 0;
    let totalRechargeCostValue = 0;
    let totalTransaction = 0;

    transactions.forEach((transation) => {
      if (!isEmpty(transation.totalAmount && transation.userCommission)) {
        totalRechargeSoldValue += Number(transation.totalAmount);
        totalRechargeCostValue +=
          Number(transation.totalAmount) - Number(transation.userCommission);
        totalTransaction++;
      }
    });
    setTotalRechargeSold(Number(totalRechargeSoldValue).toFixed(2));
    setTotalRechargeCost(Number(totalRechargeCostValue).toFixed(2));
    setTotalTransaction(Number(totalTransaction).toFixed(2));
  };

  const refreshTransactionsByDate = async () => {
    if (setting === undefined) return;
    if (rateUsa === undefined) return;
    if (wallet == undefined || wallet.data === undefined) return;
    if (user === undefined || user?.data === undefined) return;
    const refreshTransactions = user?.data?.roles[ROLES.agent]
      ? await FirestoreTransactions.LoadByFilter(filters, user?.data?.uid)
      : await FirestoreTransactions.LoadByFilterAndBusinessId(
          filters,
          business?.uid
        );

    dispatch(transactionsAddMany(refreshTransactions));
    computeTotalCommission(refreshTransactions);
    computeTotalRechargeStats(refreshTransactions);
  };

  // Run when Dependencies changes
  useEffect(() => {
    refreshTransactionsByDate();
  }, [recharge.status, filters, business?.uid, user?.data?.uid]);

  const renderTransactionsStatsCollection = () => {
    return (
      <div>
        <dl className="mt-5 flex flex-col lg:flex-row lg:space-x-4">
          <TransactionStats
            data={totalTransaction}
            title="Number of Recharges"
            type={TransactionStatsType.TOTAL_RECHARGE_SENT}
          />
          <TransactionStats
            data={totalRechargeSold}
            title="Total Recharges Sold"
            type={TransactionStatsType.TOTAL_RECHARGE_SOLD}
          />
          <TransactionStats
            data={totalRechargeCost}
            title="Total Recharges Cost"
            type={TransactionStatsType.TOTAL_RECHARGE_SOLD}
          />
          <TransactionStats
            data={totalCommission}
            title="My Commission"
            type={TransactionStatsType.TOTAL_COMMISION}
          />
          <WalletStats
            minAmount={wallet?.data?.minBalance}
            data={wallet?.data?.amount}
            title="Wallet Balance"
            type={TransactionStatsType.TOTAL_WALLET_BALANCE}
          />
        </dl>
      </div>
    );
  };

  return setting && rateUsa && wallet.data
    ? renderTransactionsStatsCollection()
    : "";
};

export default TransactionsStatsCollection;
