import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { UserAddIcon } from "@heroicons/react/solid";
import ComboBoxesBase from "../../components/atoms/ComboBoxesBase";
import { HttpClient } from "../../api/server/service";
import { SUCCESS } from "../logs/logStatus";
import {
  BUSINESS_ADD_AGENT_FAILED,
  BUSINESS_ADD_AGENT_PENDING,
  BUSINESS_ADD_AGENT_SUCCESS,
  BUSINESS_READY,
} from "./businessStatus";
import BusinessState from "./BusinessState";
import { BUSINESS_DETAILS } from "../../router/routeNames";
import { selectedCountryByIsoName } from "../countries/countriesSlice";
import { Link } from "react-router-dom";
import { businessStatus } from "./businessSlice";
import { isEmpty } from "../../utils/stringHelper";
import { isValidEmail, isValidPassword } from "../../utils/regexHelper";
import FirestoreUser from "../../api/firebase/firestore.user";
import { ROLES } from "../authentication/models";

const AgentForm = ({ business }) => {
  const dispatch = useDispatch();

  const businessesStatus = useSelector((state) => state.businesses.status);

  const countriesEntities = useSelector((state) => state.countries.entities);
  const countries = Object.values(countriesEntities);

  const userCountry = useSelector((state) =>
    selectedCountryByIsoName(state, business?.countryCode)
  );

  const user = useSelector((state) => state.user);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState(userCountry);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectedCountry = (data) => {
    setCountry(data);
  };

  const handleAddAgent = async () => {
    try {
      if (isEmpty(firstName)) {
        setErrorMessage("First Name is required");
        return;
      }

      if (isEmpty(lastName)) {
        setErrorMessage("Last Name is required");
        return;
      }

      if (!isValidEmail(email)) {
        setErrorMessage("Invalid email address");
        return;
      }

      if (!isValidPassword(password)) {
        setErrorMessage(
          "Password must have, 1 Upercase, lowercase, number and special character."
        );
        return;
      }
      dispatch(businessStatus(BUSINESS_ADD_AGENT_PENDING));
      const agentData = {
        name: `${firstName} ${lastName}`,
        email: email,
        password: password,
        emailVerified: true,
        displayName: `${firstName} ${lastName}`,
      };

      const httpClient = new HttpClient(process.env.REACT_APP_HTTP_USERS);
      const agent = await httpClient.Post("/create", JSON.stringify(agentData));
      if (agent.status === SUCCESS) {
        await FirestoreUser.Update({
          uid: agent.data.uid,
          name: `${firstName} ${lastName}`,
          isActive: true,
          businessId: business.uid,
          countryCode: country.isoName,
          currencyCode: country.currencyCode,
          country: country.name,
          displayName: `${firstName} ${lastName}`,
          roles: {
            partner: false,
            manager: false,
            agent: user.data?.roles[ROLES.manager],
            employee: user.data?.roles[ROLES.partner],
          },
        });
        dispatch(businessStatus(BUSINESS_ADD_AGENT_SUCCESS));
        return;
      }
      dispatch(businessStatus(BUSINESS_ADD_AGENT_FAILED));
    } catch (error) {
      dispatch(businessStatus(BUSINESS_ADD_AGENT_FAILED));
    }
  };

  if (businessesStatus !== BUSINESS_READY) {
    return <BusinessState route={`${BUSINESS_DETAILS}/${business.uid}`} />;
  }

  return (
    <div className="pt-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Agent Information
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Setup Agent access to your Business.
        </p>
      </div>
      <p className="text-xs font-bold my-4 text-red-700">{errorMessage}</p>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            First name
          </label>
          <div className="mt-1">
            <input
              value={firstName}
              onChange={(data) => setFirstName(data.target.value)}
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            Last name
          </label>
          <div className="mt-1">
            <input
              value={lastName}
              onChange={(data) => setLastName(data.target.value)}
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              value={email}
              onChange={(data) => setEmail(data.target.value)}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              value={password}
              onChange={(data) => setPassword(data.target.value)}
              id="password"
              name="password"
              type="password"
              autoComplete="epasswordmail"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className="mt-1">
            <ComboBoxesBase
              selectOptions={countries}
              selectedOption={country}
              handleSelect={handleSelectedCountry}
              title="Select Country"
            />
          </div>
        </div>
      </div>
      <div className="mt-10 flex flex-row justify-end">
        <Link
          to={`${BUSINESS_DETAILS}/${business.uid}`}
          className="text-center w-44 px-6 py-3 border-2 border-gray-50 shadow-sm text-base font-medium rounded-md text-blue-1000
                                 bg-white hover:bg-gray-25 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
        >
          Cancel
        </Link>
        <button
          onClick={handleAddAgent}
          type="button"
          className="inline-flex self-end w-44 px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white
                                 bg-blue-1000 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Agent
          <UserAddIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

AgentForm.propTypes = {
  business: PropTypes.object.isRequired,
};
export default AgentForm;
