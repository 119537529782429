import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import withBoostrapApp from "../../components/HOC/withBoostrapApp";
import FirestorePaymentMethod from "../../api/firebase/firestore.paymentMethods";
import withSidebar from "../../components/HOC/withSidebar";
import {
  paymentMethodsAddMany,
  paymentMethodStatus,
} from "../../features/paymentMethods/paymentMethodsSlice";
import { PAYMENTMETHODS_READY } from "../../features/paymentMethods/paymentMethodState";
import WalletState from "../../features/wallets/WalletState";
import { WALLETS_READY } from "../../features/wallets/walletStatus";
import {
  WALLET_ADD_PAYMENTMETHOD,
  WALLET_RECHARGE,
} from "../../router/routeNames";
import { isEmpty } from "../../utils/stringHelper";
import PaymentMethodCollection from "../../features/paymentMethods/PaymentMethodCollection";
import WalletFund from "../../features/wallets/WalletFund";
import AutoRechargeConfirmation from "../../features/wallets/AutoRechargeConfirmation";

const Wallets = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    async function loadWallets() {
      if (wallet.data === undefined) return;

      const paymentMethods = await FirestorePaymentMethod.LoadByWalletId(
        wallet?.data?.uid
      );
      if (paymentMethods !== null && paymentMethods.length > 0) {
        dispatch(paymentMethodsAddMany(paymentMethods));
        dispatch(paymentMethodStatus(PAYMENTMETHODS_READY));
      }
    }

    loadWallets();
  }, [wallet?.data?.uid]);

  const renderWalletSection = () => {
    if (
      wallet?.status === WALLETS_READY &&
      isEmpty(wallet?.data?.paymentMethodId)
    ) {
      return (
        <WalletState
          title="Setup Payment Method"
          description="Add Your First Payment Method to your Wallets"
          route={WALLET_ADD_PAYMENTMETHOD}
        />
      );
    }
    return <PaymentMethodCollection />;
  };

  const renderWalletPage = () => {
    return (
      <div className="font-Poppins">
        <div className="md:flex md:items-center md:justify-between mb-4">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              My Wallets
            </h2>
          </div>
          <Link
            to={WALLET_RECHARGE}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                        text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Balance
          </Link>
        </div>
        <div className="mb-2">
          <AutoRechargeConfirmation />
        </div>
        <div className="flex-1 flex-col xl:flex-row relative z-0 flex overflow-hidden rounded-3xl">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="inset-0 py-6">
              <div className="rounded-lg">
                <WalletFund
                  amount={wallet?.data?.amount}
                  currency={user?.data?.currencyCode}
                />
                {renderWalletSection()}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  };

  return renderWalletPage();
};

export default compose(withBoostrapApp, withSidebar)(Wallets);
