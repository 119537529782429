import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { LOGIN } from "../../router/routeNames";
import { isValidEmail } from "../../utils/regexHelper";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import { useDispatch } from "react-redux";
import {
  authenticationEmailAdd,
  authenticationStatusUpdate,
} from "./authenticationSlice";
import { AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT } from "./authState";

const SendEmailResetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const sendEmailResetPassword = async () => {
    try {
      if (!isValidEmail(email)) {
        setErrorMessage("Invalid Email Address");
        return;
      }
      dispatch(authenticationEmailAdd(email));
      await FirebaseAuth.SendResetPasswordEmailLink(email);
      dispatch(
        authenticationStatusUpdate(AUTHENTICATION_RESET_PASSWORD_EMAIL_SENT)
      );
      return;
    } catch (error) {
      setErrorMessage(
        "Failed sent Email Reset Password. Contact Support@jlrecharge.com"
      );
    }
  };

  return (
    <div>
      <div className="flex justify-center align-middle content-center">
        <div className="py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white rounded-2xl shadow-sm">
          <div className="md:w-80">
            <div className="flex flex-row">
              <Link to={LOGIN}>
                <ArrowCircleLeftIcon
                  className="h-10 w-10 mr-2 text-blue-1000"
                  aria-hidden="true"
                />
              </Link>
              <h2 className="mx-6 text-2xl my-2 font-extrabold text-gray-900">
                Reset Password
              </h2>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <form action="/" method="POST" className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        autoComplete="email"
                        placeholder="Enter your Email Address"
                        required
                        value={email}
                        onChange={(data) => setEmail(data.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-1">
                      <span className="text-xs text-red-600 font-semibold">
                        {errorMessage}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={sendEmailResetPassword}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmailResetPassword;
