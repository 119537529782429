import React, { useState } from "react";
import { EMAIL_VERIFIED, SIGNUP } from "../../router/routeNames";

import { InboxInIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import { authenticationSignOut } from "./authenticationSlice";

const AuthEmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const authentication = useSelector((state) => state.authentication);
  const [message, setMessage] = useState("");

  const resendEmail = async () => {
    try {
      const auth = getAuth();
      await sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}${EMAIL_VERIFIED}?email=${user?.data?.email}`,
      });
    } catch (error) {
      setMessage(error.message);
    }
  };

  const tryAnotherEmail = async () => {
    // Sign up current user
    const auth = getAuth().currentUser;
    if (auth) {
      await FirebaseAuth.SignOut();
      dispatch(authenticationSignOut(null));
    }
    navigate(SIGNUP, { replace: true });
    return;
  };

  const renderCtaAction = () => {
    if (user?.data?.uid) {
      return (
        <button
          onClick={resendEmail}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Re-Send Email
        </button>
      );
    }

    return (
      <a
        href={`mailto:${user?.data?.email || authentication?.data?.email}`}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Open Email
      </a>
    );
  };

  return (
    <div className="flex justify-center align-middle content-center">
      <div className="py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white rounded-2xl shadow-sm">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-row justify-center">
            <h2 className="mx-6 my-2 text-2xl font-extrabold text-gray-900">
              Verify Email
            </h2>
          </div>

          <div className="mt-8">
            <div className="flex flex-col">
              <div className="mb-6 flex justify-center">
                <InboxInIcon className="text-blue-1000 h-24 w-24" />
              </div>
              <div className="flex flex-col justify-center">
                <h2 className="font-semibold mb-6 text-center text-lg">
                  Check your email
                </h2>
                <p className="text-gray-500 mb-6 text-center">
                  We have sent you an email verification link. <br />
                  Please follow the instructions and continue.
                </p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-red-600 font-medium">{message}</p>
                {renderCtaAction()}
              </div>
              <div className="flex flex-col text-center">
                <p className="text-gray-500 font-normal text-center mb-2">
                  Did not received the email? Check span folder. or{" "}
                </p>
                <button
                  onClick={tryAnotherEmail}
                  className="inline-flex px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium 
								rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
								focus:ring-indigo-500 text-center"
                >
                  {" "}
                  Try another email address.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthEmailVerification;
