import React from "react";
import PropTypes from "prop-types";
import { getRole } from "../authentication/models";
import { getInitial } from "../../utils/stringHelper";

const Agent = ({ agent }) => {
  return (
    <div
      key={agent.email}
      className="relative rounded-lg border border-gray-50 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    >
      <div className="flex-shrink-0">
        <span className="h-10 w-10 rounded-full bg-blue-1000 p-5 text-white font-bold">
          {getInitial(agent)}
        </span>
      </div>
      <div className="flex-1 min-w-0">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{agent.name}</p>
          <p className="text-sm text-gray-500 truncate">{agent.email}</p>
          <p className="truncate inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800">
            {getRole(agent.roles)}
          </p>
        </a>
      </div>
    </div>
  );
};

Agent.propTypes = {
  agent: PropTypes.object.isRequired,
};
export default Agent;
