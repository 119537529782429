import { getAuth, getIdToken } from "firebase/auth";
import { getAppCheckToken } from "../firebase/firebase.appcheck";

import { HttpCommunication } from "../http";

export class ProviderClient {
  constructor(url) {
    this.Communication = new HttpCommunication(url);
  }

  static async GetProviderEndPoint(providerName, endpoint) {
    switch (providerName.toLowerCase().replace(" ", "")) {
      case "ding":
      case "reloadly":
      case "cvmovel":
        return endpoint.indexOf("providers") < 0
          ? `providers/${endpoint}`
          : endpoint;
      default:
        return endpoint;
    }
  }

  async GetProviderByPhone(node, data) {
    const auth = getAuth();
    const { currentUser } = auth;
    const userToken = await getIdToken(currentUser, true);
    const { token } = await getAppCheckToken();
    return this.Communication.Post(node, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "X-Firebase-AppCheck": token,
      },
    });
  }

  async Reacharge(node, data) {
    const auth = getAuth();
    const { currentUser } = auth;
    const userToken = await getIdToken(currentUser, true);
    const { token } = await getAppCheckToken();
    return this.Communication.Post(node, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "X-Firebase-AppCheck": token,
      },
    });
  }
}
