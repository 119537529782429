import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN, SPLASH } from "../../router/routeNames";
import { isValidEmail, isValidPassword } from "../../utils/regexHelper";
import { isEmpty } from "../../utils/stringHelper";
import AuthEmailVerification from "./AuthEmailVerification";
import { AUTHENTICATION_FAILED, AUTHENTICATION_SUCESS } from "./authState";
import Logo from "../../components/svg/Logo";
import {
  authenticationAdd,
  authenticationMessage,
  authenticationStatusUpdate,
} from "./authenticationSlice";
import Alert from "../../components/alert/Alert";
import { AuthCode } from "./models";
import FirebaseAuth from "../../api/firebase/firebase.auth";
import { setRole } from "../user/userSlice";

const AuthEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector((state) => state.authentication);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleJoinJLRBusiness = async () => {
    if (isEmpty(email)) {
      dispatch(authenticationMessage("Email is required."));
      return;
    }

    if (!isValidEmail(email)) {
      dispatch(authenticationMessage("Invalid Email."));
      return;
    }

    if (isEmpty(password)) {
      dispatch(authenticationMessage("Invalid Email."));
      return;
    }

    if (!isValidPassword(password)) {
      dispatch(
        authenticationMessage(
          "Password must have, 1 Updater letter, 1 number, special character, and lowercase letter."
        )
      );
      return;
    }
    const authData = {
      email: email,
      password: password,
      role: authentication?.data?.role,
    };
    const authResulted = await FirebaseAuth.SignUp(authData);
    if (!authResulted?.uid) {
      return;
    }
    dispatch(
      setRole({
        uid: authResulted?.uid,
        isActive: false,
        roles: {
          partner: true,
          manager: false,
          agent: false,
          employee: false,
        },
      })
    );
    if (authResulted.status === AuthCode.SUCCESS) {
      dispatch(authenticationAdd(authResulted));
      dispatch(authenticationStatusUpdate(AUTHENTICATION_SUCESS));
      navigate(SPLASH, { replace: true });
    } else {
      dispatch(authenticationMessage(authResulted.message));
      dispatch(authenticationStatusUpdate(AUTHENTICATION_FAILED));
    }
  };

  useEffect(() => {
    if (authentication === undefined) return;

    if (authentication.status !== AUTHENTICATION_SUCESS) return;

    if (authentication?.data === undefined) return;

    if (authentication?.data?.uid !== undefined) {
      navigate(SPLASH, { replace: true });

      return;
    }
  }, [authentication.status]);

  if (
    authentication.status === AUTHENTICATION_SUCESS &&
    !authentication?.data.emailVerified
  ) {
    return <AuthEmailVerification />;
  }

  return (
    <div className="min-h-full flex bg-gray-25 rounded-xl shadow-md">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <Logo />
            <h2 className="mt-10 text-3xl font-extrabold text-gray-900">
              Join our Space
            </h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <Alert
                title={` ${
                  authentication.data?.status === AuthCode.FAILED
                    ? "Sign Failed"
                    : ""
                } `}
                message={authentication.data?.message}
                type="error"
              />
              <form action="#" method="POST" className="space-y-6 mt-5">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(data) => setEmail(data.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(data) => setPassword(data.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleJoinJLRBusiness}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Join our Space
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <div className="text-sm">
                    <Link
                      to={LOGIN}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Already have an account?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
};

export default AuthEmail;
