import React from "react";
import { useSelector } from "react-redux";
import { CreditCardIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

import { selectPaymentMethodById } from "./paymentMethodsSlice";

const PaymentMethodDebitCard = ({ id }) => {
  const paymentMethod = useSelector((state) =>
    selectPaymentMethodById(state, id)
  );
  if (paymentMethod === undefined) {
    return <div>No Payment Method Selected.</div>;
  }

  return (
    <div
      className={`col-span-1 flex rounded-md ${
        paymentMethod?.isDefault
          ? "border-2 border-solid border-purple-400"
          : "border-2 border-solid border-gray-100"
      }`}
    >
      <div className="flex-shrink-0 flex items-center justify-center w-16 text-blue-1000 text-sm font-medium rounded-l-md p-4 bg-gray-50">
        <CreditCardIcon className="text-blue-1000" size="2x" />
      </div>
      <div className="flex-1 flex items-center justify-between bg-white rounded-r-md truncate">
        <div className="flex-1 px-2 py-2 text-sm truncate">
          <p className="text-gray-900 capitalize font-semibold">
            {paymentMethod.brand}*** {paymentMethod.last4}
          </p>
          <p className="text-gray-500">
            {paymentMethod.expMonth} / {paymentMethod.expYear}
          </p>
        </div>
      </div>
    </div>
  );
};

PaymentMethodDebitCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PaymentMethodDebitCard;
