import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleWithInfo from "../../components/atoms/ToggleWithInfo";
import BalanceOptions from "./BalanceOptions";
import WalletProcessing from "./WalletProcessing";
import { walletUpdate } from "./walletSlice";
import { WALLETS_READY } from "./walletStatus";

const AuthRecharge = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const handleToggleChange = (isActive) => {
    const walletData = {
      uid: wallet?.data?.uid,
      autoRecharge: isActive,
    };
    dispatch(walletUpdate(walletData));
  };

  const handleBalanceAmountMinimunChange = (value) => {
    const walletData = {
      uid: wallet?.data?.uid,

      minBalance: Number(value),
    };
    dispatch(walletUpdate(walletData));
  };

  const handleBalanceAmountChange = (value) => {
    const walletData = {
      uid: wallet?.data?.uid,
      balanceAmount: Number(value),
    };
    dispatch(walletUpdate(walletData));
  };

  if (wallet.status !== WALLETS_READY) {
    return <WalletProcessing />;
  }
  if (!wallet?.data) {
    return;
  }
  return (
    <div className="px-8 pb-5">
      <div className="flex flex-row justify-between">
        <h2 className="text-gray-700 text-2xl font-semibold tracking-wide">
          Setup Auto Balance
        </h2>
      </div>
      <div className="w-full flex flex-row justify-center">
        <div className="border-2 border-dashed border-gray-200 rounded-full my-6 w-20 h-20 py-6">
          <p
            className={` ${
              wallet?.data?.autoRecharge ? "text-green-600" : "text-red-700"
            } text-3xl font-bold text-center justify-center content-center align-middle`}
          >
            {wallet?.data?.autoRecharge ? "ON" : "OFF"}
          </p>
        </div>
      </div>

      <div className="py-5 rounded-lg">
        <ToggleWithInfo
          title="Auto Balance"
          description="Enable/Disable add automatically balance to your wallet"
          isActive={wallet?.data?.autoRecharge}
          onToggleChange={handleToggleChange}
        />

        <BalanceOptions
          title="Recharge Wallet when Balance is Below:"
          balanceAmount={wallet?.data?.minBalance}
          balanceAmountOptions={wallet?.data?.minBalanceOptions}
          handleBalanceAmount={handleBalanceAmountMinimunChange}
        />

        <BalanceOptions
          title="Amount to Auto Recharge Wallet."
          balanceAmount={wallet?.data?.balanceAmount}
          balanceAmountOptions={wallet?.data?.balanceAmountOptions}
          handleBalanceAmount={handleBalanceAmountChange}
        />
      </div>
    </div>
  );
};

export default AuthRecharge;
